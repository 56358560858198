import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, Card, CardContent, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import EmailIcon from "@material-ui/icons/Email";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import Header from "../Header/Header";
import { getUserInfo } from "../../services/user";
import { RootContext } from "../AppContext/AppContext";
import { useHistory } from "react-router-dom";
import Loading from "../Util/Loading";
import NotFound from "../Util/NotFound";
import FollowingCard from "../Activities/FollowingCard";
import ForumCard from "../Forum/ForumCard";
import "../Activities/Activities.scss";

export default function ProfileUser() {
    const { user, doLogout } = useContext(RootContext);
    let history = useHistory();
    const [isFetching, setIsFetching] = useState(false);
    const [profileMenuSelected, setProfileMenuSelected] = useState(0);
    const [userInfo, setUserInfo] = useState({
        fetched: false,
        following: [],
        foruns: [],
    });

    const [alertStatus, setAlertStatus] = useState({
        error: { status: false, message: "" },
    });

    useEffect(() => {
        setIsFetching(true);
        fetchUserInfo();
    }, []);

    async function fetchUserInfo() {
        let infos = await getUserInfo(user._id);
        if (infos.error) {
            setAlertStatus({
                ...alertStatus,
                error: {
                    status: true,
                    message:
                        "Houve um erro ao acessar suas informações. Por favor tente mais tarde",
                },
            });
        } else {
            infos["fetched"] = true;
            setUserInfo(infos);
            setIsFetching(false);
        }
    }

    function logout() {
        doLogout();
        history.push("/");
    }

    return (
        <>
            <Header />
            <div className="profile-page">
                <div className="activities-page">
                    {alertStatus.error.status && (
                        <Alert
                            style={{ marginTop: 5 }}
                            severity="error"
                            onClose={() => {
                                setAlertStatus({ ...alertStatus, error: { status: false } });
                            }}
                        >
                            {alertStatus.error.message}
                        </Alert>
                    )}
                    <Grid container>
                        <Grid
                            style={{ marginTop: "10px" }}
                            className="mural-menu"
                            item
                            xs={12}
                        >
                            <h3
                                onClick={(e) =>
                                    profileMenuSelected === 1
                                        ? setProfileMenuSelected(0)
                                        : setProfileMenuSelected(1)
                                }
                                className={
                                    profileMenuSelected === 1 ? "mural-menu-selected" : ""
                                }
                            >
                                Minhas informações
                            </h3>
                            <Grid container>
                                <Grid xs={12} lg={4}>
                                    <Card
                                        style={{
                                            display: profileMenuSelected === 1 ? "block" : "none",
                                        }}
                                        className={"following-card"}
                                    >
                                        <CardContent style={{ paddingTop: 5, paddingBottom: 5 }}>
                                            <Typography
                                                style={{ fontSize: 10 }}
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                Leitor(a)
                                        </Typography>
                                            <Typography variant="h6" component="h6">
                                                {user.name}
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                <EmailIcon /> &nbsp;{user.email}
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                <LocationCityIcon /> &nbsp;{user.address.city}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            style={{ marginTop: "10px" }}
                            className="mural-menu"
                            item
                            xs={12}
                        >
                            <h3
                                onClick={(e) =>
                                    profileMenuSelected === 2
                                        ? setProfileMenuSelected(0)
                                        : setProfileMenuSelected(2)
                                }
                                className={
                                    profileMenuSelected === 2 ? "mural-menu-selected" : ""
                                }
                            >
                                Seguindo
                        </h3>
                            <Grid style={{ display: profileMenuSelected === 2 ? "inline-flex" : "none" }} container>
                                {userInfo.following.map((entity, key) => (
                                    <Grid item xs={12} lg={4} key={key}>
                                        <FollowingCard key={key} entity={entity} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid
                            style={{ marginTop: "10px" }}
                            className="mural-menu"
                            item
                            xs={12}
                        >
                            <h3
                                onClick={(e) =>
                                    profileMenuSelected === 7
                                        ? setProfileMenuSelected(0)
                                        : setProfileMenuSelected(7)
                                }
                                className={
                                    profileMenuSelected === 7 ? "mural-menu-selected" : ""
                                }
                                style={{ color: "red" }}
                            >
                                Excluir perfil
                            </h3>
                            <Grid style={{ display: profileMenuSelected === 7 ? "block" : "none" }} content >
                                <Grid style={{ textAlign: "center" }} item xs={12}>
                                    <Button
                                        style={{ minWidth: 100 }}
                                        className="delete-btn"
                                        variant="outlined"
                                    >
                                        Excluir
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            style={{
                                textAlign: "center",
                                marginTop: 50,
                                paddingLeft: 10,
                                paddingRight: 10,
                            }}
                            item
                            xs={12}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                className="edit-info-btn"
                                style={{ maxWidth: 400 }}
                                onClick={logout}
                            >
                                Sair da minha conta
                        </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}
