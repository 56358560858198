import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./Header.scss";
const rnbcLogo = require("../../assets/images/Logo-MapadaLeitura.png");

export default function Header() {
  return (
    <Grid
      className="default-header"
      container
    >
      <Grid component={Link} to={"/"} item xs={12} style={{ textAlign: "center" }} lg={1}>
        <img src={rnbcLogo} style={{ width: 150 }} />
      </Grid>
      {/* <Grid
        className="header-title"
        item
        xs={8}
        lg={11}
        style={{
          lineHeight: "2em",
          fontSize: "1.4em",
        }}
      >
        Mapa da Leitura
      </Grid> */}
    </Grid>
  );
}
