import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, FormControlLabel } from "@material-ui/core";
import PurpleCheckbox from "../Util/PurpleCheckbox";

const oficinasOptions = {
  teatro: "Teatro",
  bonecos: "Bonecos",
  brincadeiras: "Brincadeiras",
  jogos: "Jogos",
  atividades: "Atividades",
  danca: "Dança",
  meditacao: "Meditação",
  pintura: "Pintura",
  escrita: "Escrita",
  musica: "Musica",
  fotografia: "Fotografia",
  cinema: "Cinema",
  ilustracao: "Ilustração",
  poesia: "Poesia",
  artesanato: "Artesanato",
  capoeira: "Aula de Capoeira",
};

const dancaOptions = {
  ballet: "Ballet",
  hiphop: "Hip-Hop",
  jazz: "Jazz",
  funk: "Funk",
};

const musicInstrOptions = {
  corda: "Instrumentos de corda",
  africana: "Instrumentos de música africana",
  sopro: "Instrumentos de sopro",
  percussao: "Instrumentos de percussão",
};

const musicOptions = {
  instrumentos: "Instrumentos musicais",
  canto: "Canto",
};

function LibraryRegisterOficinas({
  setNextPartForm,
  oficinas,
  screenSequence,
  handleOficinasUpdate,
  handleNextScreen,
  isEditing
}) {
  const [oficinasFormData, setOficinasFormData] = useState({
    teatro: false,
    bonecos: false,
    brincadeiras: false,
    jogos: false,
    atividades: false,
    danca: false,
    meditacao: false,
    pintura: false,
    escrita: false,
    musica: false,
    fotografia: false,
    cinema: false,
    ilustracao: false,
    poesia: false,
    artesanato: false,
    capoeira: false,
    outro: false,
    outroText: "",
  });

  const [dancaFormData, setDancaFormData] = useState({
    ballet: false,
    hiphop: false,
    jazz: false,
    funk: false,
    outro: false,
    outroText: "",
  });

  const [musicFormData, setMusicFormData] = useState({
    instrumentos: false,
    canto: false,
    outro: false,
    outroText: "",
  });

  const [musicInstrFormData, setMusicInstrFormData] = useState({
    corda: false,
    africana: false,
    sopro: false,
    percussao: false,
    outro: false,
    outroText: "",
  });

  useEffect(() => {
    let oficinasMore = [...oficinas.oficinas];
    let danca = [...oficinas.danceMore];
    let music = [...oficinas.musicMore];
    let musicInstr = [...oficinas.instrMusicaisMore];

    let newOficinasFormData = {
      teatro: false,
      bonecos: false,
      brincadeiras: false,
      jogos: false,
      atividades: false,
      danca: false,
      meditacao: false,
      pintura: false,
      escrita: false,
      musica: false,
      fotografia: false,
      cinema: false,
      ilustracao: false,
      poesia: false,
      artesanato: false,
      capoeira: false,
      outro: false,
      outroText: "",
    };

    let newDancaFormData = {
      ballet: false,
      hiphop: false,
      jazz: false,
      funk: false,
      outro: false,
      outroText: "",
    };

    let newMusicFormData = {
      instrumentos: false,
      canto: false,
      outro: false,
      outroText: "",
    };

    let newMusicInstrFormData = {
      corda: false,
      africana: false,
      sopro: false,
      percussao: false,
      outro: false,
      outroText: "",
    };

    Object.keys(oficinasOptions).forEach((key) => {
      let index = oficinasMore.indexOf(oficinasOptions[key]);
      if (index != -1) {
        newOficinasFormData[key] = true;
        oficinasMore.splice(index, 1);
      }
    });

    if (oficinasMore.length === 1) {
      newOficinasFormData.outro = true;
      newOficinasFormData.outroText = oficinasMore[0];
    }
    setOficinasFormData(newOficinasFormData);

    Object.keys(dancaOptions).forEach((key) => {
      let index = danca.indexOf(dancaOptions[key]);
      if (index != -1) {
        newDancaFormData[key] = true;
        danca.splice(index, 1);
      }
    });

    if (danca.length === 1) {
      newDancaFormData.outro = true;
      newDancaFormData.outroText = danca[0];
    }
    setDancaFormData(newDancaFormData);

    Object.keys(musicOptions).forEach((key) => {
      let index = music.indexOf(musicOptions[key]);
      if (index != -1) {
        newMusicFormData[key] = true;
        music.splice(index, 1);
      }
    });

    if (music.length === 1) {
      newMusicFormData.outro = true;
      newMusicFormData.outroText = music[0];
    }
    setMusicFormData(newMusicFormData);

    Object.keys(musicInstrOptions).forEach((key) => {
      let index = musicInstr.indexOf(musicInstrOptions[key]);
      if (index != -1) {
        newMusicInstrFormData[key] = true;
        musicInstr.splice(index, 1);
      }
    });

    if (musicInstr.length === 1) {
      newMusicInstrFormData.outro = true;
      newMusicInstrFormData.outroText = musicInstr[0];
    }
    setMusicInstrFormData(newMusicInstrFormData);
  }, []);

  const handleCheckboxChange = (event) => {
    let newOficinas = {...oficinasFormData};
    newOficinas[event.target.name] = event.target.checked;
    setOficinasFormData(newOficinas);

    if(isEditing) handleOficinasEditUpdate(newOficinas, dancaFormData, musicFormData, musicInstrFormData);
  };

  const handleDancaCheckboxChange = (event) => {
    let newDanca = {...dancaFormData};
    newDanca[event.target.name] = event.target.checked;
    setDancaFormData(newDanca);

    if(isEditing) handleOficinasEditUpdate(oficinasFormData, newDanca, musicFormData, musicInstrFormData);
  };

  const handleMusicaCheckboxChange = (event) => {
    let newMusic = {...musicFormData};
    newMusic[event.target.name] = event.target.checked;
    setMusicFormData(newMusic);

    if(isEditing) handleOficinasEditUpdate(oficinasFormData, dancaFormData, newMusic, musicInstrFormData);
  };

  const handleMusicinstrCheckboxChange = (event) => {
    let newMusicInstr = {...musicInstrFormData};
    newMusicInstr[event.target.name] = event.target.checked;
    setMusicInstrFormData(newMusicInstr);

    if(isEditing) handleOficinasEditUpdate(oficinasFormData, dancaFormData, musicFormData, newMusicInstr);
  };

  const handleOficinasEditUpdate = (newOficinas, newDance, newMusic, newMusicInstr) => {
    let oficinas = [];
    let danceMore = [];
    let musicMore = [];
    let instrMusicaisMore = [];

    for (let key in newOficinas) {
      if (newOficinas[key] === true && key != "outro") {
        oficinas.push(oficinasOptions[key]);
      } else if (newOficinas[key].length > 0) {
        oficinas.push(newOficinas[key]);
      }
    }

    if (newOficinas.danca) {
      for (let key in newDance) {
        if (newDance[key] === true && key != "outro") {
          danceMore.push(dancaOptions[key]);
        } else if (newDance[key].length > 0) {
          danceMore.push(newDance[key]);
        }
      }
    }

    if (newOficinas.musica) {
      for (let key in newMusic) {
        if (newMusic[key] === true && key != "outro") {
          musicMore.push(musicOptions[key]);
        } else if (newMusic[key].length > 0) {
          musicMore.push(newMusic[key]);
        }
      }
    }

    if (newMusic.instrumentos) {
      for (let key in newMusicInstr) {
        if (newMusicInstr[key] === true && key != "outro") {
          instrMusicaisMore.push(musicInstrOptions[key]);
        } else if (newMusicInstr[key].length > 0) {
          instrMusicaisMore.push(newMusicInstr[key]);
        }
      }
    }

    handleOficinasUpdate({
      oficinas: oficinas,
      danceMore: danceMore,
      musicMore: musicMore,
      instrMusicaisMore: instrMusicaisMore
    });
  };

  const handleFormValidation = () => {
    let oficinas = [];
    let danceMore = [];
    let musicMore = [];
    let instrMusicaisMore = [];
    let nextScreen = 0;

    for (let key in oficinasFormData) {
      if (oficinasFormData[key] === true && key != "outro") {
        oficinas.push(oficinasOptions[key]);
      } else if (oficinasFormData[key].length > 0) {
        oficinas.push(oficinasFormData[key]);
      }
    }

    if (oficinasFormData.danca) {
      for (let key in dancaFormData) {
        if (dancaFormData[key] === true && key != "outro") {
          danceMore.push(dancaOptions[key]);
        } else if (dancaFormData[key].length > 0) {
          danceMore.push(dancaFormData[key]);
        }
      }
    }

    if (oficinasFormData.musica) {
      for (let key in musicFormData) {
        if (musicFormData[key] === true && key != "outro") {
          musicMore.push(musicOptions[key]);
        } else if (musicFormData[key].length > 0) {
          musicMore.push(musicFormData[key]);
        }
      }
    }

    if (musicFormData.instrumentos) {
      for (let key in musicInstrFormData) {
        if (musicInstrFormData[key] === true && key != "outro") {
          instrMusicaisMore.push(musicInstrOptions[key]);
        } else if (musicInstrFormData[key].length > 0) {
          instrMusicaisMore.push(musicInstrFormData[key]);
        }
      }
    }

    handleOficinasUpdate({
      oficinas: oficinas,
      danceMore: danceMore,
      musicMore: musicMore,
      instrMusicaisMore: instrMusicaisMore,
    });

    for (let i = 0; i <= screenSequence.length; i++) {
      if (screenSequence[i] === 1) {
        if (i + 1 < screenSequence.length) {
          nextScreen = screenSequence[i + 1];
          break;
        }
      }
    }
    if (nextScreen === 0) {
      handleNextScreen();
    } else {
      setNextPartForm(nextScreen);
    }
  };

  return (
    <Grid container>
      {isEditing && <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
          <span className="form-helper-span">
            <b>Detalhes de Oficinas</b>
          </span>
        </Grid>
      }
      {Object.keys(oficinasOptions).map((key) => {
        return (
          <Grid item xs={12} key={key}>
            <FormControlLabel
              control={
                <PurpleCheckbox
                  checked={oficinasFormData[key]}
                  onChange={handleCheckboxChange}
                  name={key}
                />
              }
              label={oficinasOptions[key]}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <PurpleCheckbox
              checked={oficinasFormData.outro}
              onChange={handleCheckboxChange}
              name="outro"
            />
          }
          label="Outro: "
        />
        <TextField
          className="create-forum-field"
          id="accessibilidade-outro"
          value={oficinasFormData.outroText}
          onChange={(e) =>
            setOficinasFormData({
              ...oficinasFormData,
              outroText: e.target.value,
            })
          }
        />
      </Grid>
      {oficinasFormData.danca && (
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Que tipos de oficinas de dança?</b>
            </span>
          </Grid>
          {Object.keys(dancaOptions).map((key) => {
            return (
              <Grid item xs={12} key={key}>
                <FormControlLabel
                  control={
                    <PurpleCheckbox
                      checked={dancaFormData[key]}
                      onChange={handleDancaCheckboxChange}
                      name={key}
                    />
                  }
                  label={dancaOptions[key]}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <PurpleCheckbox
                  checked={dancaFormData.outro}
                  onChange={handleDancaCheckboxChange}
                  name="outro"
                />
              }
              label="Outro: "
            />
            <TextField
              className="create-forum-field"
              id="danca-outro"
              value={dancaFormData.outroText}
              onChange={(e) =>
                setDancaFormData({
                  ...dancaFormData,
                  outroText: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      )}
      {oficinasFormData.musica && (
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Que tipos de oficinas de música?</b>
            </span>
          </Grid>
          {Object.keys(musicOptions).map((key) => {
            return (
              <Grid item xs={12} key={key}>
                <FormControlLabel
                  control={
                    <PurpleCheckbox
                      checked={musicFormData[key]}
                      onChange={handleMusicaCheckboxChange}
                      name={key}
                    />
                  }
                  label={musicOptions[key]}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <PurpleCheckbox
                  checked={musicFormData.outro}
                  onChange={handleMusicaCheckboxChange}
                  name="outro"
                />
              }
              label="Outro: "
            />
            <TextField
              className="create-forum-field"
              id="musica-outro"
              value={musicFormData.outroText}
              onChange={(e) =>
                setMusicFormData({
                  ...musicFormData,
                  outroText: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      )}
      {musicFormData.instrumentos && (
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Quais instrumentos musicais?</b>
            </span>
          </Grid>
          {Object.keys(musicInstrOptions).map((key) => {
            return (
              <Grid item xs={12} key={key}>
                <FormControlLabel
                  control={
                    <PurpleCheckbox
                      checked={musicInstrFormData[key]}
                      onChange={handleMusicinstrCheckboxChange}
                      name={key}
                    />
                  }
                  label={musicInstrOptions[key]}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <PurpleCheckbox
                  checked={musicInstrFormData.outro}
                  onChange={handleMusicinstrCheckboxChange}
                  name="outro"
                />
              }
              label="Outro: "
            />
            <TextField
              className="create-forum-field"
              id="musica-instrumentos-outro"
              value={musicInstrFormData.outroText}
              onChange={(e) =>
                setMusicInstrFormData({
                  ...musicInstrFormData,
                  outroText: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      )}

      {!isEditing && <Grid className="buttons-grid" item xs={12}>
          <Button
            className="back-cancel-button"
            variant="outlined"
            onClick={(e) => {
              setNextPartForm(0);
            }}
          >
            Voltar
          </Button>
          <Button
            className="submit-button"
            variant="outlined"
            onClick={handleFormValidation}
          >
            Avançar
          </Button>
        </Grid>
      }
    </Grid>
  );
}

export default LibraryRegisterOficinas;
