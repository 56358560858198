import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, FormControlLabel } from "@material-ui/core";
import PurpleCheckbox from "../Util/PurpleCheckbox";

const formacoesOptions = {
  arte: "Arte",
  leitura: "Leitura e literatura",
  biblioteconomia: "Biblioteconomia",
  politica: "Politicas públicas",
  filosofia: "Filosofia",
};

function LibraryRegisterFormacoes({
  setNextPartForm,
  formacoesMore,
  screenSequence,
  handleSubActionsUpdate,
  handleNextScreen,
  isEditing
}) {
  const [formacoesFormData, setFormacoesFormData] = useState({
    arte: false,
    leitura: false,
    biblioteconomia: false,
    politica: false,
    filosofia: false,
    outro: false,
    outroText: "",
  });

  useEffect(() => {
    let formacoes = [...formacoesMore];
    let newFormacoesFormData = {
      arte: false,
      leitura: false,
      biblioteconomia: false,
      politica: false,
      filosofia: false,
      outro: false,
      outroText: "",
    };

    Object.keys(formacoesOptions).forEach((key) => {
      let index = formacoes.indexOf(formacoesOptions[key]);
      if (index != -1) {
        newFormacoesFormData[key] = true;
        formacoes.splice(index, 1);
      }
    });

    if (formacoes.length === 1) {
      newFormacoesFormData.outro = true;
      newFormacoesFormData.outroText = formacoes[0];
    }

    setFormacoesFormData(newFormacoesFormData);
  }, []);

  const handleCheckboxChange = (event) => {
    let formacoes = {...formacoesFormData};
    formacoes[event.target.name] = event.target.checked;
    setFormacoesFormData(formacoes);

    if(isEditing) updateFormacoesEditForm(formacoes);
  };

  const updateFormacoesEditForm = (formacoes) => {
    let newFormacoes = [];

    for (let key in formacoes) {
      if (formacoes[key] === true && key != "outro") {
        newFormacoes.push(formacoesOptions[key]);
      } else if (formacoes[key].length > 0) {
        newFormacoes.push(formacoes[key]);
      }
    }

    handleSubActionsUpdate("formacoesMore", newFormacoes);
  };

  const handleBackScreen = () => {
    let nextScreen = 0;
    for (let i = 0; i <= screenSequence.length; i++) {
      if (screenSequence[i] === 2) {
        if (i - 1 >= 0) {
          nextScreen = screenSequence[i - 1];
          break;
        }
      }
    }
    setNextPartForm(nextScreen);
  };

  const handleFormValidation = () => {
    let formacoes = [];
    let nextScreen = 0;

    for (let key in formacoesFormData) {
      if (formacoesFormData[key] === true && key != "outro") {
        formacoes.push(formacoesOptions[key]);
      } else if (formacoesFormData[key].length > 0) {
        formacoes.push(formacoesFormData[key]);
      }
    }

    handleSubActionsUpdate("formacoesMore", formacoes);

    for (let i = 0; i <= screenSequence.length; i++) {
      if (screenSequence[i] === 2) {
        if (i + 1 < screenSequence.length) {
          nextScreen = screenSequence[i + 1];
          break;
        }
      }
    }
    if (nextScreen === 0) {
      console.log("handleNextScreen");
      handleNextScreen();
    } else {
      setNextPartForm(nextScreen);
    }
  };

  return (
    <Grid container>
      {isEditing && <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
          <span className="form-helper-span">
            <b>Detalhes de Formações</b>
          </span>
        </Grid>
      }
      {Object.keys(formacoesOptions).map((key) => {
        return (
          <Grid item xs={12} key={key}>
            <FormControlLabel
              control={
                <PurpleCheckbox
                  checked={formacoesFormData[key]}
                  onChange={handleCheckboxChange}
                  name={key}
                />
              }
              label={formacoesOptions[key]}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <PurpleCheckbox
              checked={formacoesFormData.outro}
              onChange={handleCheckboxChange}
              name="outro"
            />
          }
          label="Outro: "
        />
        <TextField
          className="create-forum-field"
          id="formacoes-outro"
          value={formacoesFormData.outroText}
          onChange={(e) =>
            setFormacoesFormData({
              ...formacoesFormData,
              outroText: e.target.value,
            })
          }
        />
      </Grid>

     {!isEditing && <Grid className="buttons-grid" item xs={12}>
          <Button
            className="back-cancel-button"
            variant="outlined"
            onClick={handleBackScreen}
          >
            Voltar
          </Button>
          <Button
            className="submit-button"
            variant="outlined"
            onClick={handleFormValidation}
          >
            Avançar
          </Button>
        </Grid>
      }
    </Grid>
  );
}

export default LibraryRegisterFormacoes;
