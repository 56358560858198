import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  DialogContent,
  TextField,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { RootContext } from "../AppContext/AppContext";
import { updateLibrary } from "../../services/library";
import IconButton from "@material-ui/core/IconButton";
import NumberFormat from "react-number-format";
import CloseIcon from "@material-ui/icons/Close";
import PurpleCheckbox from "../Util/PurpleCheckbox";
import LibraryRegisterAccessibility from "./LibraryRegisterAccessibility";
import LibraryRegisterCulturalActions from "./LibraryRegisterCulturalActions";
import LibraryRegisterAcervo from "./LibraryRegisterAcervo";
import LibraryRegisterPartners from "./LibraryRegisterPartners";
import { getAddress } from "../../services/location";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const statesOptions = [
  "AC",
  "AL",
  "AM",
  "AP",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RO",
  "RS",
  "RR",
  "SC",
  "SE",
  "SP",
  "TO",
];

const institutionOptions = {
  rnbc: "RNBC",
  literasampa: "Litera Sampa",
  beabah: "Beabah",
  soudeminas: "Sou de minas, Uai",
  marleitores: "Mar de Leitores",
  baixada: "Baixada Literária",
  tecendo: "Tecendo uma rede de leitura",
  rbcs: "RBCS",
  jangada: "Jangada Literária",
  releitura: "Releitura PE",
  ilha: "Ilha Literária",
  amazonia: "Amazônia Literária",
  bahia: "Oxe Bahia",
};

const EditLibrary = ({ libraryInfo, editingLibrary, setLibraryInfo, setEditingLibrary, setAlertStatus }) => {
  const [libraryForm, setLibraryForm] = useState(libraryInfo);
  const [institutionFormData, setInstitutionFormData] = useState({});
  const [passwordError, setPasswordError] = useState(false);
  const [addressChanged, setAddressChanged] = useState(false);
  const { user, setUser } = useContext(RootContext);

  //Set LibraryInfo to the library Form
  useEffect(() => {
    if (Array.isArray(libraryInfo.institution) && libraryInfo.institution.length > 0) {
      let institutions = [...libraryInfo.institution];

      let newInstitutionsFormData = {
        rnbc: false,
        literasampa: false,
        beabah: false,
        soudeminas: false,
        marleitores: false,
        baixada: false,
        tecendo: false,
        rbcs: false,
        jangada: false,
        releitura: false,
        ilha: false,
        amazonia: false,
        bahia: false,
        outro: false,
        outroText: "",
      };

      Object.keys(institutionOptions).forEach((key) => {
        let index = institutions.indexOf(institutionOptions[key]);
        if (index != -1) {
          newInstitutionsFormData[key] = true;
          institutions.splice(index, 1);
        }
      });

      if (institutions.length === 1) {
        newInstitutionsFormData.outro = true;
        newInstitutionsFormData.outroText = institutions[0];
      }

      setInstitutionFormData(newInstitutionsFormData);
    }
    setLibraryForm(libraryInfo);
  }, [libraryInfo]);

  useEffect(() => {
    if (libraryForm.newPassword && libraryForm.newPassword.length > 0) {
      if (libraryForm.newPassword != libraryForm.confirmPassword) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
  }, [libraryForm.newPassword, libraryForm.confirmPassword]);


  async function handleSubmit() {
    let result = await updateLibrary(libraryInfo._id, libraryForm, user, addressChanged);
    if (result.error) {
      setAlertStatus({
        success: {
          status: false,
          message: ""
        },
        error: {
          status: true,
          message:
            "Houve um erro ao atualizar a biblioteca, por favor tente mais tarde",
        },
      });
    } else {
      setAlertStatus({
        error: {
          status: false,
          message: "",
        },
        success: {
          status: true,
          message:
            "Informações atualizadas com sucesso",
        },
      });
      setLibraryInfo(result);
      let userUpdated = { ...user };
      userUpdated.password = result.password;
      userUpdated.name = result.name;
      setUser(userUpdated);
    }
    setEditingLibrary(false);
  }

  function handleSubActionsUpdate(key, value) {
    let newActions = { ...libraryForm.actions };
    newActions[key] = value;
    setLibraryForm({ ...libraryForm, actions: newActions });
  }

  function handleAddressItemChange(key, value) {
    setAddressChanged(true);
    let newAddress = { ...libraryForm.address };
    newAddress[key] = value;
    setLibraryForm({ ...libraryForm, address: newAddress });
  }

  const handleStateSelectChange = (e) => {
    setAddressChanged(true);
    let newAddress = { ...libraryForm.address };
    newAddress.state = e.target.value;
    setLibraryForm({ ...libraryForm, address: newAddress });
  };

  const handleOficinasUpdate = (obj) => {
    let newActions = { ...libraryForm.actions };
    newActions.oficinas = obj.oficinas;
    newActions.musicMore = obj.musicMore;
    newActions.instrMusicaisMore = obj.instrMusicaisMore;
    newActions.danceMore = obj.danceMore;
    setLibraryForm({ ...libraryForm, actions: newActions });
  };

  function handleInstitutionsUpdate(institutions) {
    let newInstitutions = [];
    for (let key in institutions) {
      if (institutions[key] === true && key != "outro") {
        newInstitutions.push(institutionOptions[key]);
      } else if (institutions[key].length > 0) {
        newInstitutions.push(institutions[key]);
      }
    }
    handleFormUpdate("institution", newInstitutions);
  }

  function handleFormUpdate(key, value) {
    setLibraryForm({ ...libraryForm, [key]: value });
  }

  function handleZipCodeChange(zipCode) {
    if (zipCode.length === 8) {
      getAddress(zipCode)
        .then((response) => response.json())
        .then((data) => {
          let newAddress = { ...libraryForm.address };
          newAddress.addressLine = `Bairro ${data.bairro} ${data.logradouro}`;
          newAddress.state = data.uf;
          newAddress.city = data.localidade;
          newAddress.zipCode = zipCode;
          setLibraryForm({ ...libraryForm, address: newAddress });
        })
        .catch((error) => console.log(error));
    } else {
      handleAddressItemChange("zipCode", zipCode);
    }
  }

  function handleCheckboxChange(event) {
    let institutions = { ...institutionFormData };
    institutions[event.target.name] = event.target.checked;
    setInstitutionFormData(institutions);
    handleInstitutionsUpdate(institutions)
  };

  return (
    <Dialog
      fullScreen
      open={editingLibrary}
      onClose={(e) => setEditingLibrary(false)}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: "relative", backgroundColor: "#a73d8f" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={(e) => setEditingLibrary(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className="create-event-title">
            Atualizar Informações
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSubmit}>
            Enviar
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              Se for necessário alterar o email cadastrado, entre contato com a equipe do Mapa da Leitura
            </span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="create-forum-field"
              fullWidth
              id="email"
              value={libraryForm.email}
              label="Email"
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Atualização de senha (deixe em branco para manter a mesma)</b>
            </span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="create-forum-field"
              fullWidth
              id="password"
              type="password"
              value={libraryForm.newPassword}
              onChange={(e) => handleFormUpdate("newPassword", e.target.value)}
              label="Nova Senha"
              variant="outlined"
              required
              error={passwordError}
              helperText={passwordError ? "A senha não confere" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="create-forum-field"
              fullWidth
              id="confirm-password"
              type="password"
              value={libraryForm.confirmPassword}
              onChange={(e) =>
                handleFormUpdate("confirmPassword", e.target.value)
              }
              label="Confirmar Nova Senha"
              variant="outlined"
              required
              error={passwordError}
              helperText={passwordError ? "A senha não confere" : ""}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Informações gerais</b>
            </span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="library-name"
              value={libraryForm.name}
              onChange={(e) =>
                handleFormUpdate("name", e.target.value)
              }
              InputLabelProps={{
                shrink: true,
              }}
              label="Nome da Biblioteca"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <span className="form-helper-span">
              A biblioteca <b>faz</b> parte da RNBC (Rede Nacional de Bibliotecas Comunitárias)?
              </span>
            <RadioGroup
              aria-label="biblioteca-rnbc"
              value={libraryForm.role}
              onChange={(e) => handleFormUpdate("role", e.target.value)}
            >
              <FormControlLabel
                control={<Radio />}
                value="library"
                label={"NÃO fazemos parte da RNBC"}
              />
              <FormControlLabel
                control={<Radio />}
                value="libraryRnbc"
                label={"Somos parte da RNBC"}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <span className="form-helper-span">
              Selecione as redes/programas (pode selecionar mais de uma) e utilizar o box "Outra" para incluir uma instituição
              de fora da lista
            </span>
            {Object.keys(institutionOptions).map((key) => {
              return (
                <div key={key}>
                  <br />
                  <FormControlLabel
                    control={
                      <PurpleCheckbox
                        checked={institutionFormData[key]}
                        onChange={handleCheckboxChange}
                        name={key}
                      />
                    }
                    label={institutionOptions[key]}
                  />
                </div>
              );
            })
            }
            <br />
            <FormControlLabel
              control={
                <PurpleCheckbox
                  checked={institutionFormData.outro}
                  onChange={handleCheckboxChange}
                  name="outro"
                />
              }
              label="Outra: "
            />
            <TextField
              className="create-forum-field"
              id="cursos-outro"
              value={institutionFormData.outroText}
              onChange={(e) =>
                setInstitutionFormData({
                  ...institutionFormData,
                  outroText: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Dados de endereço e contato</b>
            </span>
          </Grid>
          <Grid item xs={8}>
            {libraryForm.address && <NumberFormat
              id="zipCode"
              variant="outlined"
              required
              fullWidth
              label="CEP"
              format="#####-###"
              value={libraryForm.address ? libraryForm.address.zipCode : ""}
              onValueChange={(values) => {
                handleZipCodeChange(values.value);
              }}
              error={
                libraryForm.address.zipCode.length != 8 &&
                  libraryForm.address.zipCode.length > 0
                  ? true
                  : false
              }
              helperText={
                libraryForm.address.zipCode.length != 8
                  ? "O CEP deve conter 8 digitos"
                  : ""
              }
              customInput={TextField}
            />}
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined">
              <InputLabel id="state-label">Estado</InputLabel>
              <Select
                labelId="state-label"
                style={{ minWidth: 100 }}
                id="state"
                value={libraryForm.address ? libraryForm.address.state : ""}
                onChange={handleStateSelectChange}
                label="Estado"
                required
              >
                {statesOptions.map((stateOp) => {
                  return (
                    <MenuItem key={stateOp} value={stateOp}>
                      {stateOp}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="create-forum-field"
              fullWidth
              id="city"
              value={libraryForm.address ? libraryForm.address.city : ""}
              onChange={(e) => {
                handleAddressItemChange("city", e.target.value);
              }}
              label="Cidade"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="create-forum-field"
              fullWidth
              id="addressLine"
              value={libraryForm.address ? libraryForm.address.addressLine : ""}
              onChange={(e) => {
                handleAddressItemChange("addressLine", e.target.value);
              }}
              label="Endereço"
              helperText={
                "Apenas nome da rua, bairro e número, utilize o campo Complemento para indicar outras informações que ajudem a localizar a biblioteca"
              }
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="create-forum-field"
              fullWidth
              id="address-complement"
              value={libraryForm.address ? libraryForm.address.complement : ""}
              onChange={(e) => {
                handleAddressItemChange("complement", e.target.value);
              }}
              label="Complemento"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="create-forum-field"
              fullWidth
              id="phones"
              helperText={
                libraryForm.phones ? "" : "É necessário indicar pelo menos um telefone de contato"
              }
              error={libraryForm.phones ? false : true}
              value={libraryForm.phones}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                handleFormUpdate("phones", e.target.value);
              }}
              label="Telefones"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <span className="form-helper-span">
              Indicar dias da semana e horário de funcionamento da biblioteca
              </span>
            <TextField
              style={{ marginTop: 5 }}
              className="create-forum-field"
              fullWidth
              rows="4"
              multiline
              id="working-time"
              value={libraryForm.workingTime}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                handleFormUpdate("workingTime", e.target.value);
              }}
              label="Horário de Funcionamento"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Redes sociais</b>
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className="form-helper-span">
              Link do perfil da biblioteca no Facebook
              </span>
            <TextField
              style={{ marginTop: 5 }}
              className="create-forum-field"
              fullWidth
              id="facebook"
              value={libraryForm.facebook}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                handleFormUpdate("facebook", e.target.value);
              }}
              label="Facebook"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <span className="form-helper-span">
              @ do perfil da biblioteca no Instagram
              </span>
            <TextField
              style={{ marginTop: 5 }}
              className="create-forum-field"
              fullWidth
              id="Instagram"
              value={libraryForm.instagram}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                handleFormUpdate("instagram", e.target.value);
              }}
              label="instagram"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Dados sobre Acessibilidade</b>
            </span>
          </Grid>
          <LibraryRegisterAccessibility
            libraryForm={libraryForm}
            handleFormUpdate={handleFormUpdate}
            handleBackScreen={() => { }}
            handleNextScreen={() => { }}
            isEditing={true}
          />
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Ações culturais promovidas pela Biblioteca</b>
            </span>
          </Grid>
          <LibraryRegisterCulturalActions
            libraryForm={libraryForm}
            handleSubActionsUpdate={handleSubActionsUpdate}
            handleBackScreen={() => { }}
            handleOficinasUpdate={handleOficinasUpdate}
            handleNextScreen={() => { }}
            isEditing={true}
          />
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Acervo disponível na Biblioteca</b>
            </span>
          </Grid>
          <LibraryRegisterAcervo
            acervo={libraryForm.acervo}
            handleFormUpdate={handleFormUpdate}
            handleBackScreen={() => { }}
            handleNextScreen={() => { }}
            isEditing={true}
          />
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
            <span className="form-helper-span">
              <b>Informações sobre parceiros</b>
            </span>
          </Grid>
          <LibraryRegisterPartners
            libraryForm={libraryForm}
            handleFormUpdate={handleFormUpdate}
            handleBackScreen={() => { }}
            handleFormSubmit={() => { }}
            isEditing={true}
          />
          <Grid item xs={12} className="activities-btn-grid">
            <Button
              variant="outlined"
              className="cancel-btn"
              onClick={(e) => setEditingLibrary(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="outlined"
              className="create-event-btn"
              onClick={handleSubmit}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditLibrary;
