import React, { useContext } from "react";
import { Button, Grid } from "@material-ui/core";
import Header from "../Header/Header";
import { RootContext } from "../AppContext/AppContext";
import { useHistory } from "react-router-dom";
import "./Activities.scss";
import ActivitiesUser from "./ActivitiesUser";
import ActivitiesLibrary from "./ActivitiesLibrary";
const astronaut = require("../../assets/images/Astronaut.png");

export default function Activities() {
  const { user } = useContext(RootContext);
  let history = useHistory();

  return (
    <div className="activities-page">
      <Header />
      {user && user.role != "anonymous" ? (
        user.role === "user" ? (
          <ActivitiesUser />
        ) : (
            <ActivitiesLibrary />
          )
      ) : (
          <div>
            <Grid
              style={{
                textAlign: "center",
                paddingTop: 25,
                paddingLeft: 15,
                paddingRight: 15,
              }}
              container
            >
              <Grid item xs={12}>
                <img src={astronaut} style={{ width: 250 }} alt="image" />
              </Grid>
              <Grid
                style={{
                  textAlign: "center",
                  fontFamily: "Syne",
                  color: "#a73d8f",
                  letterSpacing: "0.025em",
                }}
                item
                xs={12}
              >
                <h3 style={{ marginBottom: 0, marginTop: 0 }}>
                  Para disfrutar e acompanhar eventos das bibliotecas
                  comunitárias, assim como participar dos fóruns é necessário
                  realizar login ou cadastre-se
              </h3>
              </Grid>
              <Grid
                style={{ textAlign: "center" }}
                className="activities-btn-grid"
                item
                xs={12}
              >
                <Button
                  variant="outlined"
                  className="cancel-btn"
                  style={{ minWidth: 107 }}
                  onClick={(e) => history.push("/user-register")}
                >
                  Cadastro
              </Button>
                <Button
                  variant="outlined"
                  className="cancel-btn"
                  style={{ minWidth: 107 }}
                  onClick={(e) => history.push("/login-user")}
                >
                  Login
              </Button>
              </Grid>
            </Grid>
          </div>
        )}
    </div>
  );
}
