export function getAddress(zipCode) {
  let fetchUrl =
    process.env.REACT_APP_ZIPCODE_PROVIDER_URL + "/" + zipCode + "/json";

  return fetch(fetchUrl);
}

export function getLatLng(zipCode, address, state) {
  let queryAddress = zipCode + "+" + address.split(" ").join("+") + "+" + state;

  let fetchUrl =
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
    encodeURIComponent(queryAddress) +
    "&key=" +
    process.env.REACT_APP_GOOGLE_GEOCODING_KEY;
  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error in getting Lat/Long");
        }
      })
      .then((resp) => {
        return resp.results[0].geometry.location;
      })
      .catch(function () {
        return {
          lat: 0.0,
          lng: 0.0,
        };
      });
    return resp;
  } catch (error) {
    return {
      lat: 0.0,
      lng: 0.0,
    };
  }
}
