import React from "react";
import { Card, CardContent, Typography, Link } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LinkIcon from "@material-ui/icons/Link";
import "./Activities.scss";

export default function FollowingCard({ entity, isDetailed, userId }) {
  return (
    <Card className={"following-card"}>
      <CardContent style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Typography style={{ fontSize: 10 }} color="textSecondary" gutterBottom>
          {entity.role === "user" ? "Leitor(a)" : "Biblioteca Comunitária"}
        </Typography>
        <Typography variant="h6" component="h6">
          {entity.name}
        </Typography>
        <Typography variant="body2" component="p">
          <EmailIcon /> &nbsp;{entity.email}
        </Typography>
        <Typography variant="body2" component="p">
          <LocationCityIcon /> &nbsp;{entity.address.city}
        </Typography>
        {isDetailed && entity.following.includes(userId) &&
          <Typography variant="body2" component="i">
            Este(a) leitor(a) segue sua biblioteca comunitária
          </Typography>
        }
        {entity.role != "user" && (
          <Typography variant="body2" component="p">
            <LinkIcon /> &nbsp;
            <Link href={`/library/${entity._id}`}>Ver Perfil</Link>
          </Typography>
        )}
      </CardContent>
    </Card >
  );
}
