import React, { useEffect, useState } from "react";
import "./App.css";
import Map from "../Map/Map";
import Forum from "../Forum/Forum";
import Library from "../Library/Library";
import LoginLibrary from "../Login/LoginLibrary";
import LoginUser from "../Login/LoginUser";
import Home from "../Login/Home";
import UserRegister from "../User/UserRegister";
import LibraryRegister from "../Library/LibraryRegister";
import RequestResetPassword from "../RedefinePassword/RequestResetPassword";
import Rnbc from "../Rnbc/Rnbc";
import Activities from "../Activities/Activities";
import Profile from "../Profile/Profile";
import AppContext from "../AppContext/AppContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import Navigation from "../Navigation/Navigation";
import TopNavigation from "../Navigation/TopNavigation";
import ResetPassword from "../RedefinePassword/ResetPassword";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  return (
    <AppContext>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/login-library">
            <LoginLibrary />
          </Route>
          <Route path="/login-user">
            <LoginUser />
          </Route>
          <Route path="/activities">
            <Activities />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/logout">TODO: Logout logic</Route>
          <Route path="/map" component={Map}></Route>
          <Route path="/rnbc">
            <Rnbc />
          </Route>
          <Route path="/forum">
            <Forum />
          </Route>
          <Route path="/user-register">
            <UserRegister />
          </Route>
          <Route path="/library-register">
            <LibraryRegister />
          </Route>
          <Route path="/library/:id" children={<Library />} />
          <Route exact path="/forgot">
            <RequestResetPassword />
          </Route>
          <Route path="/forgot/:token/:entityId" children={<ResetPassword />} />
          <Route path="*">404 NOT FOUND</Route>
        </Switch>

        <div className="content">
          {isMobile ? <Navigation /> : <TopNavigation />}

        </div>
      </Router>
    </AppContext>
  );
}

export default App;
