import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import InfoWindowCustom from "./InfoWindowCustom";
import { Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchBar from "material-ui-search-bar";
import { getLibrary, searchLibrary } from "../../services/library";
import "./Map.scss";

const image = require("../../assets/images/marcroxo.png");
const mapMobileStyles = {
  bottom: 80,
  position: "absolute",
  width: "100%",
  height: "calc(100vh-80px)",
}
const mapDesktopStyles = {
  bottom: 80,
  position: "absolute",
  width: "100%",
  height: "calc(100vh-80px)",
}

export class MapContainer extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
    redirect: false,
    libraries: [],
    activeSearch: false,
    searchTerm: "",
    searchPlaceholder: "Termo de busca",
    searchFilters: {
      all: true,
      name: true,
      distance: false,
    },
    userPosition: {},
    error: { status: false, message: "" },
  };

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({ activeSearch: true, userPosition: position.coords });
          return true;
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
              console.log("An unknown error occurred.");
              break;
          }
          this.setState({
            ...this.state,
            error: {
              status: true,
              message:
                "Não foi possível acessar sua localização, tente buscar manualmente pelo mapa",
            },
          });
          return false;
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      return false;
    }
  };

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
  };

  onInfoWindowClick = () => {
    this.props.history.push("/library/" + this.state.activeMarker.name);
  };

  updateFilter = (filter) => {
    let filters = { ...this.state.searchFilters };

    if (filter === "name") {
      filters.name = true;
      filters.distance = false;
      this.setState({ activeSearch: true });
    } else if (filter === "distance") {
      filters.name = false;
      filters.distance = true;
      this.getLocation();
    }

    this.setState({ searchFilters: filters });
  };

  onSearchClicked = async () => {
    if (this.state.searchFilters.distance) {
      if (!Number(this.state.searchTerm)) {
        this.setState({
          ...this.state,
          error: {
            status: true,
            message:
              "Para buscar por distância você deve digitar uma distância em km da sua posição. Indique apenas a distância númerica no campo de busca.",
          },
        });
        return false;
      }
    }
    let result = await searchLibrary(
      this.state.searchTerm,
      this.state.searchFilters,
      this.state.userPosition
    );
    if (result.error) {
      this.setState({
        ...this.state,
        error: {
          status: true,
          message: "Houve um erro e não foi possível completar sua busca",
        },
      });
      this.setState({ activeSearch: false });
    } else {
      this.setState({ libraries: result, activeSearch: false });
    }
  };

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        showingInfoWindow: false,
      });

    if (this.state.activeSearch) {
      this.setState({
        activeSearch: false,
      });
    }
  };

  navigateToLibrary = () => {
    window.open(
      "https://maps.google.com/maps?daddr=(" +
      this.state.activeMarker.position.lat() +
      "," +
      this.state.activeMarker.position.lng() +
      ")"
    );
  };

  fetchLibraries = () => {
    getLibrary()
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({ libraries: data });
        }
      })
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <div style={{ overflow: "hidden" }}>
        {this.state.activeSearch && (
          <div className="search-area">
            <Grid container className="search-filter-label" spacing={1}>
              <Grid item xs={12} lg={3} style={{ color: "#676767", display: "flex", alignItems: "center" }}>
                {this.state.searchFilters.name === true ? "Digite o termo de busca e clique em Buscar" : "Digite a distância (em km) clique em Buscar"}
              </Grid>
              <Grid item xs={9} lg={5}>
                <SearchBar
                  value={this.state.searchTerm}
                  placeholder={this.state.searchPlaceholder}
                  className="search-bar"
                  onChange={(newValue) => {
                    this.setState({ searchTerm: newValue });
                  }}
                  onCancelSearch={(e) => this.setState({ searchTerm: "" })}
                  style={{ boardRadius: "50px" }}
                  onRequestSearch={() => {
                    console.log("Request search");
                  }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                lg={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  className="search-libraries-btn"
                  onClick={(e) => this.onSearchClicked()}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        {!this.state.activeSearch && (
          <Grid container style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, marginTop: window.innerWidth > 800 ? 60 : 5 }}>
            <Grid item xs={5} lg={2} className="search-title-grid">
              Buscar bibliotecas:
            </Grid>
            <Grid item lg={4}>
              <Button
                variant="outlined"
                className={"search-filter-btn"}

                onClick={(e) => this.updateFilter("name")}
              >
                Nome
              </Button>
              <Button
                variant="outlined"
                className={"search-filter-btn"}
                onClick={(e) => this.updateFilter("distance")}
              >
                Distância
              </Button>
            </Grid>
          </Grid>
        )}
        {this.state.error.status && (
          <Alert
            style={{ marginTop: 5 }}
            severity="error"
            onClose={() => {
              this.setState({ ...this.state, error: { status: false } });
            }}
          >
            {this.state.error.message}
          </Alert>
        )}
        <div className="map-div" >
          <Map
            mapTypeControl={false}
            streetViewControl={false}
            fullscreenControl={false}
            zoomControl={false}
            style={window.innerWidth > 800 ? mapDesktopStyles : mapMobileStyles}
            google={this.props.google}
            zoom={4}
            onReady={this.fetchLibraries}
            onClick={this.onMapClicked}
            initialCenter={{ lat: -16.808718, lng: -50.119033 }}
          >
            {this.state.libraries.map((lb) => {
              return (
                <Marker
                  name={lb._id}
                  title={lb.name}
                  onClick={this.onMarkerClick}
                  position={{
                    lat: lb.location.coordinates[1],
                    lng: lb.location.coordinates[0],
                  }}
                  icon={{
                    url: image,
                  }}
                />
              );
            })}
            <InfoWindowCustom
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
            >
              <div className="info-window">
                <h3>{this.state.activeMarker.title}</h3>
                <Button
                  variant="outlined"
                  className="search-filter-btn"
                  type="submit"
                  onClick={(e) => this.onInfoWindowClick()}
                >
                  Conhecer
              </Button>
                <Button
                  variant="outlined"
                  className="search-filter-btn"
                  type="submit"
                  onClick={(e) => this.navigateToLibrary()}
                >
                  Rotas
              </Button>
              </div>
            </InfoWindowCustom>
          </Map>
        </div>

      </div>
    );
  }
}

export default GoogleApiWrapper((props) => ({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  language: "pt"
}))(MapContainer);
