import React, { Fragment, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  makeStyles,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MapIcon from "@material-ui/icons/Map";
import ForumIcon from "@material-ui/icons/Forum";
import InfoIcon from "@material-ui/icons/Info";
import PersonIcon from '@material-ui/icons/Person';
import { RootContext } from "../AppContext/AppContext";

const useStyles = makeStyles({
  root: {
    bottom: 0,
    position: "fixed",
    width: "100%",
    backgroundColor: "#a73d8f",
  },
});

export default function Navigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { user } = useContext(RootContext);
  const routesWithoutNav = [
    "/",
    "/login-user",
    "/user-register",
    "/login-library",
    "/library-register",
  ];
  let location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes("map") ||
      location.pathname.includes("library")
    ) {
      setValue(0);
    } else if (location.pathname.includes("activities")) {
      setValue(1);
    } else if (location.pathname.includes("forum")) {
      setValue(2);
    } else if (location.pathname.includes("rnbc")) {
      setValue(3);
    }
  }, []);
  return (
    <Fragment>
      {!location.pathname.includes("forgot") &&
        !routesWithoutNav.includes(location.pathname) && (
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction
              component={Link}
              to={"/map"}
              label="Mapa"
              style={{ color: "white" }}
              icon={<MapIcon style={{ color: "white" }} />}
            />
            <BottomNavigationAction
              component={Link}
              to={"/activities"}
              label="Atividades"
              style={{ color: "white" }}
              icon={<FavoriteIcon style={{ color: "white" }} />}
            />
            <BottomNavigationAction
              component={Link}
              to={"/forum"}
              label="Fórum"
              style={{ color: "white" }}
              icon={<ForumIcon style={{ color: "white" }} />}
            />
            <BottomNavigationAction
              component={Link}
              to={"/rnbc"}
              label="Info"
              style={{ color: "white" }}
              icon={<InfoIcon style={{ color: "white" }} />}
            />
            <BottomNavigationAction
              component={Link}
              to={"/profile"}
              label="Perfil"
              style={{ color: "white" }}
              icon={<PersonIcon style={{ color: "white" }} />}
            />
          </BottomNavigation>
        )}
    </Fragment>
  );
}
