import React, { useContext, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import rnbcLogo from "../../assets/images/Logo-MapadaLeitura.png";
import { RootContext } from "../AppContext/AppContext";
import queryString from "query-string";
import "./Login.scss";
const paths = [
  "forgot",
  "map",
  "activities",
  "login",
  "register",
  "forum",
  "library",
  "rnbc",
];

function Home() {
  const { user } = useContext(RootContext);
  let history = useHistory();

  useEffect(() => {
    let path = queryString.parse(window.location.search).path;
    if (user && user.role != "anonymous") {
      if (!path) {
        history.push("/map");
      } else {
        processRouteWithPath(path)
      }
    } else if (path) {
      processRouteWithPath(path)
    }
  }, []);

  function processRouteWithPath(path) {
    for (let key in paths) {
      if (path.includes(paths[key])) {
        history.push(path);
        break;
      }
    }
  }
  return (
    <div className="login-root">
      <div className="login-page">
        <Grid
          style={{ paddingTop: "5vh", paddingBottom: "5vh" }}
          container
          className="login-input-container"
        >
          <Grid component={Link} to={"/"} item xs={12}>
            <img src={rnbcLogo} className="home-logo" />
          </Grid>

          <Grid item xs={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
            <div style={{ color: "#676767" }}>
              <p>O Mapa da Leitura conecta bibliotecas comunitárias de todo o país. Aqui é possível trocar experiências entre bibliotecas, divulgar suas atividades, se relacionar com leitores, colaboradores, voluntários e parceiros. </p>
              <p>Este aplicativo é uma realização da Rede Nacional de Bibliotecas Comunitárias (RNBC).</p>
            </div>
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <Button
              variant="outlined"
              component={Link}
              to={"/login-user"}
              fullWidth
              className="no-login-btn"
            >
              Acessar como Leitor(a)
                </Button>
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <Button
              variant="outlined"
              component={Link}
              to={"/login-library"}
              fullWidth
              className="no-login-btn"
            >
              Acessar como Biblioteca Comunitária
                </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Home;
