import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link, useHistory } from "react-router-dom";
import rnbcLogo from "../../assets/images/Logo-MapadaLeitura.png";
import { userAuth } from "../../services/user";
import "./Login.scss";
import { RootContext } from "../AppContext/AppContext";

function LoginLibrary() {
  let history = useHistory();
  const { user, setUser } = useContext(RootContext);
  const [userForm, setUserForm] = useState({ email: "", password: "" });
  const [formError, setFormError] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
  });

  async function handleLogin() {
    if (userForm.email && userForm.password) {
      setFormError(false);

      let user = await userAuth(userForm);

      if (user.error) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message: user.error.message,
          },
        });
      } else {
        setUser(user);
        history.push("/activities");
      }
    } else {
      setFormError(true);
    }
  }

  useEffect(() => {
    if (user && user.role != "anonymous") {
      history.push("/activities");
    }
  }, [user]);

  return (
    <div className="login-root">
      <div className="login-page">
        <Grid container className="login-input-container">
          <Grid component={Link} to={"/"} item xs={12}>
            <img src={rnbcLogo} className="home-logo" />
          </Grid>
          <Grid item xs={12}>
            <h2>Espaço Bibliotecas</h2>
            {alertStatus.error.status && (
              <Alert
                style={{ marginTop: 5 }}
                severity="error"
                onClose={() => {
                  setAlertStatus({ ...alertStatus, error: { status: false } });
                }}
              >
                {alertStatus.error.message}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <TextField
              error={formError}
              className="create-forum-field"
              fullWidth
              id="outlined-email"
              label="Email"
              variant="outlined"
              onChange={(e) =>
                setUserForm({ ...userForm, email: e.target.value })
              }
              style={{ background: "white" }}
              required
            />
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <TextField
              error={formError}
              className="create-forum-field"
              fullWidth
              type="password"
              id="outlined-password"
              label="Senha"
              variant="outlined"
              onChange={(e) =>
                setUserForm({ ...userForm, password: e.target.value })
              }
              style={{ background: "white" }}
              required
            />
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <Button
              variant="outlined"
              onClick={handleLogin}
              fullWidth
              className="login-btn"
            >
              Login
            </Button>
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <Button
              variant="outlined"
              component={Link}
              to={"/library-register"}
              fullWidth
              className="no-login-btn"
            >
              Nova Biblioteca Comunitária
            </Button>
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <a style={{ textDecoration: "none" }} href="/forgot">
              Esqueci minha senha
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default LoginLibrary;
