import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, FormControlLabel } from "@material-ui/core";
import PurpleCheckbox from "../Util/PurpleCheckbox";

const cursosOptions = {
  musica: "Música",
  danca: "Dança",
  alfabetizacao: "Alfabetização",
  informatica: "Informática",
  leitura: "Leitura e literatura",
  escrita: "Escrita",
  artesanato: "Artesanato",
  culinaria: "Culinária",
};

function LibraryRegisterCursos({
  setNextPartForm,
  cursosMore,
  screenSequence,
  handleSubActionsUpdate,
  handleNextScreen,
  isEditing
}) {
  const [cursosFormData, setcursosFormData] = useState({
    musica: false,
    danca: false,
    alfabetizacao: false,
    informatica: false,
    leitura: false,
    escrita: false,
    artesanato: false,
    culinaria: false,
    outro: false,
    outroText: "",
  });

  useEffect(() => {
    let cursos = [...cursosMore];
    let newCursosFormData = {
      musica: false,
      danca: false,
      alfabetizacao: false,
      informatica: false,
      leitura: false,
      escrita: false,
      artesanato: false,
      culinaria: false,
      outro: false,
      outroText: "",
    };

    Object.keys(cursosOptions).forEach((key) => {
      let index = cursos.indexOf(cursosOptions[key]);
      if (index != -1) {
        newCursosFormData[key] = true;
        cursos.splice(index, 1);
      }
    });

    if (cursos.length === 1) {
      newCursosFormData.outro = true;
      newCursosFormData.outroText = cursos[0];
    }

    setcursosFormData(newCursosFormData);
  }, []);

  const handleCheckboxChange = (event) => {
    let cursos = {...cursosFormData};
    cursos[event.target.name] = event.target.checked;
    setcursosFormData(cursos);

    if(isEditing) updateCursosEditForm(cursos);
  };

  const handleBackScreen = () => {
    let nextScreen = 0;
    for (let i = 0; i <= screenSequence.length; i++) {
      if (screenSequence[i] === 3) {
        if (i - 1 >= 0) {
          nextScreen = screenSequence[i - 1];
          break;
        }
      }
    }
    setNextPartForm(nextScreen);
  };

  const updateCursosEditForm = (cursos) => {
    let newCursos = [];

    for (let key in cursos) {
      if (cursos[key] === true && key != "outro") {
        newCursos.push(cursosOptions[key]);
      } else if (cursos[key].length > 0) {
        newCursos.push(cursos[key]);
      }
    }

    handleSubActionsUpdate("cursosMore", newCursos);
  };


  const handleFormValidation = () => {
    let cursos = [];
    let nextScreen = 0;

    for (let key in cursosFormData) {
      if (cursosFormData[key] === true && key != "outro") {
        cursos.push(cursosOptions[key]);
      } else if (cursosFormData[key].length > 0) {
        cursos.push(cursosFormData[key]);
      }
    }

    handleSubActionsUpdate("cursosMore", cursos);

    for (let i = 0; i <= screenSequence.length; i++) {
      if (screenSequence[i] === 3) {
        if (i + 1 < screenSequence.length) {
          nextScreen = screenSequence[i + 1];
          break;
        }
      }
    }
    if (nextScreen === 0) {
      handleNextScreen();
    } else {
      setNextPartForm(nextScreen);
    }
  };

  return (
    <Grid container>
      {isEditing && <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
          <span className="form-helper-span">
            <b>Detalhes de Cursos</b>
          </span>
        </Grid>
      }
      {Object.keys(cursosOptions).map((key) => {
        return (
          <Grid item xs={12} key={key}>
            <FormControlLabel
              control={
                <PurpleCheckbox
                  checked={cursosFormData[key]}
                  onChange={handleCheckboxChange}
                  name={key}
                />
              }
              label={cursosOptions[key]}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <PurpleCheckbox
              checked={cursosFormData.outro}
              onChange={handleCheckboxChange}
              name="outro"
            />
          }
          label="Outro: "
        />
        <TextField
          className="create-forum-field"
          id="cursos-outro"
          value={cursosFormData.outroText}
          onChange={(e) =>
            setcursosFormData({
              ...cursosFormData,
              outroText: e.target.value,
            })
          }
        />
      </Grid>
      {!isEditing && <Grid className="buttons-grid" item xs={12}>
          <Button
            className="back-cancel-button"
            variant="outlined"
            onClick={handleBackScreen}
          >
            Voltar
          </Button>
          <Button
            className="submit-button"
            variant="outlined"
            onClick={handleFormValidation}
          >
            Avançar
          </Button>
        </Grid>
      }
    </Grid>
  );
}

export default LibraryRegisterCursos;
