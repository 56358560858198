import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
const finalImg = require("../../assets/images/livro_cadastro.jpg");

function RegisterConfirmation({ name, email }) {
  return (
    <>
      <Grid container>
        <Grid item style={{ textAlign: "center" }} xs={12}>
          <img src={finalImg} style={{ width: 200 }} alt="confirmation" />
        </Grid>

        <Grid
          style={{
            color: "#676767",
            textAlign: "center",
          }}
          item
          xs={12}
          className="lib-register-confirmation"
        >
          <h2
            style={{
              fontFamily: "Syne",
              letterSpacing: "0.025em",
            }}
          >
            Cadastro {name} Realizado com sucesso!
          </h2>
          <span className="form-helper-span">
            Importante esclarecer que o cadastro no Mapa da Leitura{" "}
            <b>não implica em fazer parte da RNBC</b>. Agora você pode disfrutar
            das funcionalidades gratuitas do Mapa da Leitura. Sempre que desejar
            criar eventos para a biblioteca, criar e publicar em fóruns você
            deve realizar login utilizando a senha que acabou de criar e o email{" "}
            <b>{email}</b>
          </span>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20, textAlign: "center" }}>
          <Button
            fullWidth
            className="submit-button"
            component={Link}
            to={"/login-library"}
          >
            Realizar Login
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default RegisterConfirmation;
