import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link, useHistory, useParams } from "react-router-dom";
import backgroundImage from "../../assets/images/login2-background.PNG";
import rnbcLogo from "../../assets/images/Logo-MapadaLeitura.png";
import "../Login/Login.scss";
import { checkResetToken, resetPassword } from "../../services/user";

function ResetPassword() {
  let history = useHistory();
  const { token, entityId } = useParams();
  const [userForm, setUserForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const [user, setUser] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
    success: { status: false, message: "" },
  });

  async function getResetData() {
    setIsFetching(true);
    let resp = await checkResetToken(token, entityId);
    if (resp.message) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message: resp.message,
        },
      });
    } else {
      setUser(resp);
    }
    setIsFetching(false);
  }

  async function defineNewPassword() {
    if (userForm.password.length < 4) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message: "Sua nova senha tem que ter pelo menos 4 caracteres",
        },
      });
      return;
    }
    if (userForm.password != userForm.confirmPassword) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message: "A Senha indicada não confere.",
        },
      });
      return;
    } else if (user._id === entityId) {
      setIsFetching(true);
      let resp = await resetPassword(token, entityId, userForm.password);
      if (resp.message) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message: resp.message,
          },
        });
      } else {
        setAlertStatus({
          ...alertStatus,
          success: {
            status: true,
            message:
              "Sua nova senha foi definida com sucesso. Você será redicionado para realizar o login em instantes",
          },
        });
        setTimeout(() => {
          if (resp.role === "user") {
            history.push("/login-user");
          } else {
            history.push("/login-library");
          }
        }, 5000);
      }
      setIsFetching(false);
    }
  }

  useEffect(() => {
    getResetData();
  }, []);

  return (
    <div className="login-root">
      <div className="login-page">
        <div
          className="background-img"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>

        <Grid container className="login-input-container">
          <Grid component={Link} to={"/"} item xs={12}>
            <img src={rnbcLogo} style={{ width: 200 }} />
          </Grid>
          <Grid item xs={12}>
            <h2>Mapa da Leitura</h2>
            <h2>Definir nova senha</h2>
            {alertStatus.error.status && (
              <Alert
                style={{ marginTop: 5 }}
                severity="error"
                onClose={() => {
                  setAlertStatus({ ...alertStatus, error: { status: false } });
                }}
              >
                {alertStatus.error.message}
              </Alert>
            )}
            {alertStatus.success.status && (
              <Alert
                style={{ marginTop: 5 }}
                severity="success"
                onClose={() => {
                  setAlertStatus({
                    ...alertStatus,
                    success: { status: false },
                  });
                }}
              >
                {alertStatus.success.message}
              </Alert>
            )}
          </Grid>
          {!alertStatus.error.status && (
            <>
              <Grid item xs={12} className="login-input-grid">
                <TextField
                  className="create-forum-field"
                  fullWidth
                  type="password"
                  id="outlined-password"
                  label="Nova Senha"
                  variant="outlined"
                  style={{ background: "white" }}
                  onChange={(e) =>
                    setUserForm({ ...userForm, password: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} className="login-input-grid">
                <TextField
                  className="create-forum-field"
                  fullWidth
                  type="password"
                  id="confirm-password"
                  label="Confirmar nova senha"
                  variant="outlined"
                  style={{ background: "white" }}
                  onChange={(e) =>
                    setUserForm({
                      ...userForm,
                      confirmPassword: e.target.value,
                    })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} className="login-input-grid">
                <Button
                  variant="outlined"
                  onClick={defineNewPassword}
                  fullWidth
                  className="login-btn"
                >
                  Salvar nova senha
                </Button>
              </Grid>
            </>
          )}

          <Grid item xs={12} className="login-input-grid">
            <Button
              variant="outlined"
              component={Link}
              to={"/"}
              fullWidth
              className="no-login-btn"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ResetPassword;
