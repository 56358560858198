import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, FormControlLabel } from "@material-ui/core";
import RegisterHeader from "./RegisterHeader";
import PurpleCheckbox from "../Util/PurpleCheckbox";

const partnersOptions = {
  escola: "Escola",
  creche: "Creche",
  associacao: "Associação",
  cooperativa: "Cooperativa",
  clube: "Clube",
  posto: "Posto de saúde",
  hospital: "Hospital",
  espaco: "Espaço cultural",
  bibliotecas: "Outras bibliotecas",
  biblioPublica: "Biblioteca Pública Municipal",
  biblioEstado: "Biblioteca Pública do Estado",
  poderPublico: "Poder público",
  ongs: "ONGs",
  artistas: "Artistas",
};

function LibraryRegisterPartners({
  libraryForm,
  handleFormUpdate,
  handleBackScreen,
  handleFormSubmit,
  isEditing
}) {
  const [partnersFormData, setpartnersFormData] = useState({
    escola: false,
    creche: false,
    associacao: false,
    cooperativa: false,
    clube: false,
    posto: false,
    hospital: false,
    espaco: false,
    bibliotecas: false,
    biblioPublica: false,
    biblioEstado: false,
    poderPublico: false,
    ongs: false,
    artistas: false,
    outro: false,
    outroText: "",
  });

  useEffect(() => {
    let partners = [...libraryForm.partners];
    let newPartnersFormData = {
      escola: false,
      creche: false,
      associacao: false,
      cooperativa: false,
      clube: false,
      posto: false,
      hospital: false,
      espaco: false,
      bibliotecas: false,
      biblioPublica: false,
      biblioEstado: false,
      poderPublico: false,
      ongs: false,
      artistas: false,
      outro: false,
      outroText: "",
    };

    Object.keys(partnersOptions).forEach((key) => {
      let index = partners.indexOf(partnersOptions[key]);
      if (index != -1) {
        newPartnersFormData[key] = true;
        partners.splice(index, 1);
      }
    });

    if (partners.length === 1) {
      newPartnersFormData.outro = true;
      newPartnersFormData.outroText = partners[0];
    }

    setpartnersFormData(newPartnersFormData);
  }, []);
  
  const handleCheckboxChange = (event) => {
    let partners = {...partnersFormData};
    partners[event.target.name] = event.target.checked;
    setpartnersFormData(partners);

    if(isEditing) updateEditPartnersForm(partners);
  };

  const updateEditPartnersForm = (partners) => {
    let newPartners = [];
    for (let key in partners) {
      if (partners[key] === true && key != "outro") {
        newPartners.push(partnersOptions[key]);
      } else if (partners[key].length > 0) {
        newPartners.push(partners[key]);
      }
    }

    handleFormUpdate("partners", newPartners);
  };

  const handleFormValidation = () => {
    let partners = [];
    for (let key in partnersFormData) {
      if (partnersFormData[key] === true && key != "outro") {
        partners.push(partnersOptions[key]);
      } else if (partnersFormData[key].length > 0) {
        partners.push(partnersFormData[key]);
      }
    }

    handleFormSubmit(partners);
  };

  return (
    <>
      {!isEditing && <RegisterHeader title={"Parceiros da Biblioteca"} />}
      <Grid className="checkbox-page" container>
        {Object.keys(partnersOptions).map((key) => {
          return (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <PurpleCheckbox
                    checked={partnersFormData[key]}
                    onChange={handleCheckboxChange}
                    name={key}
                  />
                }
                label={partnersOptions[key]}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <PurpleCheckbox
                checked={partnersFormData.outro}
                onChange={handleCheckboxChange}
                name="outro"
              />
            }
            label="Outro: "
          />
          <TextField
            className="create-forum-field"
            id="partners-outro"
            value={partnersFormData.outroText}
            onChange={(e) =>
              setpartnersFormData({
                ...partnersFormData,
                outroText: e.target.value,
              })
            }
          />
        </Grid>
        {!isEditing && <Grid className="buttons-grid" item xs={12}>
            <Button
              className="back-cancel-button"
              variant="outlined"
              onClick={handleBackScreen}
            >
              Voltar
            </Button>
            <Button
              className="submit-button"
              variant="outlined"
              onClick={handleFormValidation}
            >
              Criar Biblioteca
            </Button>
          </Grid>
        }
      </Grid>
    </>
  );
}

export default LibraryRegisterPartners;
