import React, { useState } from "react";
import { Button, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import PurpleCheckbox from "../Util/PurpleCheckbox";
import Header from "../Header/Header";
import { createUser } from "../../services/user";
import { useHistory } from "react-router-dom";
import "./UserRegister.scss";

function UserRegister() {
  let history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: { city: "" },
  });

  const [errorField, setErrorField] = useState({
    name: false,
    email: false,
    password: false,
    city: false,
  });

  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
    success: { status: false, message: "" },
  });

  async function handleSubmit() {
    let errors = {
      name: false,
      email: false,
      password: false,
      rnbcNews: false,
      city: false,
    };
    let foundErrors = false;

    if (formData.email.length > 0) {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
      ) {
        errors.email = false;
      } else {
        errors.email = true;
        foundErrors = true;
      }
    }

    if (formData.password.length <= 3 || formData.confirmPassword.length <= 3) {
      errors.password = true;
      foundErrors = true;
    } else {
      if (formData.password !== formData.confirmPassword) {
        errors.password = true;
        foundErrors = true;
      } else {
        errors.password = false;
      }
    }

    if (formData.address.city.length <= 3) {
      errors.city = true;
      foundErrors = true;
    } else {
      errors.city = false;
    }

    if (formData.name.length <= 3) {
      errors.name = true;
      foundErrors = true;
    } else {
      errors.name = false;
    }
    setErrorField(errors);

    if (!foundErrors) {
      let result = await createUser(formData);
      if (result.message) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message:
              result.message,
          },
        });
      } else {
        setTimeout(() => {
          history.push("/login-user");
        }, 4000);

        setAlertStatus({
          ...alertStatus,
          success: {
            status: true,
            message:
              "Seu usuário foi cadastrado com sucesso. Agora realize o login para aproveitar o máximo do Mapa da Leitura e não ficar de fora de nenhum evento.",
          },
        });
        setFormData({
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          address: { city: "" },
        });
      }
    } else {
      setFormData({ ...formData, password: "", confirmPassword: "" });
    }
  }

  return (
    <>
      <div className="user-register-page">
        <Header />
        <div className="header-intro">
          <h2>Cadastro de Leitor</h2>
          <h3>Informações para login</h3>
        </div>
        {alertStatus.error.status && (
            <Alert
              style={{ marginTop: 5 }}
              severity="error"
              onClose={() => {
                setAlertStatus({
                  ...alertStatus,
                  error: { status: false },
                });
              }}
            >
              {alertStatus.error.message}
            </Alert>
          )}
          {alertStatus.success.status && (
            <Alert
              style={{ marginTop: 5 }}
              severity="success"
              onClose={() => {
                setAlertStatus({
                  ...alertStatus,
                  success: { status: false },
                });
              }}
            >
              {alertStatus.success.message}
            </Alert>
          )}
        <Grid className="user-register-form" container style={{ paddingTop: 15 }} spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              id="name"
              value={formData.name}
              error={errorField.name}
              helperText={errorField.name && "Este campo é obrigatório"}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              label="Nome"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              id="email"
              value={formData.email}
              error={errorField.email}
              helperText={errorField.email && "O email indicado não é válido"}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              label="Email"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              id="password"
              type="password"
              error={
                errorField.password ||
                formData.password !== formData.confirmPassword
              }
              helperText={
                (errorField.password ||
                  formData.password !== formData.confirmPassword) &&
                "As senhas indicadas não conferem. A senha deve conter pelo menos 4 caracteres"
              }
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              label="Senha"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              id="confirm-password"
              type="password"
              error={
                errorField.password ||
                formData.password !== formData.confirmPassword
              }
              helperText={
                (errorField.password ||
                  formData.password !== formData.confirmPassword) &&
                "As senhas indicadas não conferem. A senha deve conter pelo menos 4 caracteres"
              }
              value={formData.confirmPassword}
              onChange={(e) =>
                setFormData({ ...formData, confirmPassword: e.target.value })
              }
              label="Confirmar Senha"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="city"
              error={errorField.city}
              helperText={errorField.city && "Este campo é obrigatório"}
              value={formData.address.city}
              onChange={(e) =>
                setFormData({ ...formData, address: { city: e.target.value } })
              }
              label="Cidade"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <PurpleCheckbox
                  checked={formData.rnbcNews}
                  onChange={(e) =>
                    setFormData({ ...formData, rnbcNews: !formData.rnbcNews })
                  }
                  name="rnbc-news"
                />
              }
              label="Você deseja receber o boletim mensal de informações da RNBC?"
            />
          </Grid>
          <Grid className="buttons-grid" item xs={12}>
            <Button
              component={Link}
              to={"/"}
              variant="outlined"
              className="back-cancel-button"
            >
              Cancelar
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => handleSubmit()}
              className="submit-button"
            >
              Registrar
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default UserRegister;
