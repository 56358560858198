import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, FormControlLabel } from "@material-ui/core";
import RegisterHeader from "./RegisterHeader";
import PurpleCheckbox from "../Util/PurpleCheckbox";

const acervoOptions = {
  infantil: "Literatura Infantil",
  juvenil: "Juvenil",
  quadrinhos: "História em Quadrinhos",
  revistas: "Revistas",
  gibis: "Gibis",
  brasileira: "Literatura brasileira",
  estrangeira: "Literatura estrangeira",
  periferica: "Literatura periférica",
  braile: "Livros em braile",
  didaticos: "Livros didáticos",
  acervoGeral: "Acervo geral",
  africana: "Literatura africana",
  indigena: "Literatura indigena",
};

const acervoGeralOptions = {
  dicionarios: "Dicionários",
  filosofia: "Filosofia",
  psicologia: "Psicologia",
  ajuda: "Auto-ajuda",
  sociologia: "Sociologia",
  politica: "Politica",
  religiao: "Religião",
  turismo: "Turismo",
  educacao: "Educação",
  leitura: "Leitura",
  ambiente: "Meio ambiente",
  administracao: "Administração",
  arte: "Arte",
  saude: "Saúde",
  futebol: "Futebol",
  viagens: "Viagens",
  geral: "História geral",
  brasil: "História do Brasil",
  africana: "Africana",
  indigena: "Indígena",
};

function LibraryRegisterAcervo({
  acervo,
  handleFormUpdate,
  handleBackScreen,
  handleNextScreen,
  isEditing
}) {
  const [totalAcervo, setTotalAcervo] = useState(acervo.total);
  const [acervoFormData, setAcervoFormData] = useState({
    infantil: false,
    juvenil: false,
    quadrinhos: false,
    revistas: false,
    gibis: false,
    brasileira: false,
    estrangeira: false,
    periferica: false,
    braile: false,
    didaticos: false,
    acervoGeral: false,
    africana: false,
    indigena: false,
    outro: false,
    outroText: "",
  });

  const [acervoGeralFormData, setAcervoGeralFormData] = useState({
    dicionarios: false,
    filosofia: false,
    psicologia: false,
    ajuda: false,
    sociologia: false,
    politica: false,
    religiao: false,
    turismo: false,
    educacao: false,
    leitura: false,
    ambiente: false,
    administracao: false,
    arte: false,
    saude: false,
    futebol: false,
    viagens: false,
    geral: false,
    brasil: false,
    africana: false,
    indigena: false,
  });

  useEffect(() => {
    let acervoMore = [...acervo.acervo];
    let acervoGeral = [...acervo.acervoGeralMore];

    let newAcervoFormData = {
      deslocamento: false,
      sinalizacao: false,
      braile: false,
      audio: false,
      libras: false,
      outro: false,
      outroText: "",
    };

    let newAcervoGeralFormData = {
      dicionarios: false,
      filosofia: false,
      psicologia: false,
      ajuda: false,
      sociologia: false,
      politica: false,
      religiao: false,
      turismo: false,
      educacao: false,
      leitura: false,
      ambiente: false,
      administracao: false,
      arte: false,
      saude: false,
      futebol: false,
      viagens: false,
      geral: false,
      brasil: false,
      africana: false,
      indigena: false,
    };

    Object.keys(acervoOptions).forEach((key) => {
      let index = acervoMore.indexOf(acervoOptions[key]);
      if (index != -1) {
        newAcervoFormData[key] = true;
        acervoMore.splice(index, 1);
      }
    });

    if (acervoMore.length === 1) {
      newAcervoFormData.outro = true;
      newAcervoFormData.outroText = acervoMore[0];
    }

    setAcervoFormData(newAcervoFormData);

    Object.keys(acervoGeralOptions).forEach((key) => {
      let index = acervoGeral.indexOf(acervoGeralOptions[key]);
      if (index != -1) {
        newAcervoGeralFormData[key] = true;
        acervoGeral.splice(index, 1);
      }
    });

    if (acervoGeral.length === 1) {
      newAcervoGeralFormData.outro = true;
      newAcervoGeralFormData.outroText = acervoGeral[0];
    }

    setAcervoGeralFormData(newAcervoGeralFormData);
  }, []);

  const handleCheckboxChange = (event) => {
    let acervo = { ...acervoFormData };
    acervo[event.target.name] = event.target.checked;
    setAcervoFormData(acervo);

    if (isEditing) handleFormValidation(acervo, acervoGeralFormData);
  };

  const handleacervoGeralCheckboxChange = (event) => {
    let acervoGeral = { ...acervoGeralFormData };
    acervoGeral[event.target.name] = event.target.checked;
    setAcervoGeralFormData(acervoGeral);

    if (isEditing) updateEditAcervoForm(acervoFormData, acervoGeral, totalAcervo);
  };

  const handleTotalAcervoChange = (event) => {
    const newTotalAcervo = event.target.value;
    setTotalAcervo(newTotalAcervo);

    if (isEditing) updateEditAcervoForm(acervoFormData, acervoGeralFormData, newTotalAcervo)
  }

  const updateEditAcervoForm = (acervo, acervoGeral, total) => {
    let newAcervo = { acervo: [], total: total, acervoGeralMore: [] };

    for (let key in acervo) {
      if (acervo[key] === true && key != "outro") {
        newAcervo.acervo.push(acervoOptions[key]);
      } else if (acervo[key].length > 0) {
        newAcervo.acervo.push(acervo[key]);
      }
    }

    if (acervo.acervoGeral) {
      for (let key in acervoGeral) {
        if (acervoGeral[key] === true && key != "outro") {
          newAcervo.acervoGeralMore.push(acervoGeralOptions[key]);
        } else if (acervoGeral[key].length > 0) {
          newAcervo.acervoGeralMore.push(acervoGeral[key]);
        }
      }
    }
    if (totalAcervo != 0 && totalAcervo != "") {
      handleFormUpdate("acervo", newAcervo);
    }
  };

  const handleFormValidation = () => {
    let acervo = { acervo: [], total: totalAcervo, acervoGeralMore: [] };

    for (let key in acervoFormData) {
      if (acervoFormData[key] === true && key != "outro") {
        acervo.acervo.push(acervoOptions[key]);
      } else if (acervoFormData[key].length > 0) {
        acervo.acervo.push(acervoFormData[key]);
      }
    }

    if (acervoFormData.acervoGeral) {
      for (let key in acervoGeralFormData) {
        if (acervoGeralFormData[key] === true && key != "outro") {
          acervo.acervoGeralMore.push(acervoGeralOptions[key]);
        } else if (acervoGeralFormData[key].length > 0) {
          acervo.acervoGeralMore.push(acervoGeralFormData[key]);
        }
      }
    }
    if (totalAcervo != 0 && totalAcervo != "") {
      handleFormUpdate("acervo", acervo);
      handleNextScreen();
    }
  };

  return (
    <>
      {!isEditing && <RegisterHeader title={"Acervo"} />}
      <Grid className="checkbox-page" container>
        {Object.keys(acervoOptions).map((key) => {
          return (
            <Grid item xs={12} key={key}>
              <FormControlLabel
                control={
                  <PurpleCheckbox
                    checked={acervoFormData[key]}
                    onChange={handleCheckboxChange}
                    name={key}
                  />
                }
                label={acervoOptions[key]}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <PurpleCheckbox
                checked={acervoFormData.outro}
                onChange={handleCheckboxChange}
                name="outro"
              />
            }
            label="Outro: "
          />
          <TextField
            className="create-forum-field"
            id="accessibilidade-outro"
            value={acervoFormData.outroText}
            onChange={(e) =>
              setAcervoFormData({
                ...acervoFormData,
                outroText: e.target.value,
              })
            }
          />
        </Grid>
        {acervoFormData.acervoGeral && (
          <>
            <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
              <span className="form-helper-span">
                <b>Selecione items que compõem o Acervo Geral da Biblioteca</b>
              </span>
            </Grid>
            <Grid container>
              {Object.keys(acervoGeralOptions).map((key) => {
                return (
                  <Grid item xs={12} key={key}>
                    <FormControlLabel
                      control={
                        <PurpleCheckbox
                          checked={acervoGeralFormData[key]}
                          onChange={handleacervoGeralCheckboxChange}
                          name={key}
                        />
                      }
                      label={acervoGeralOptions[key]}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TextField
            className="create-forum-field"
            variant="outlined"
            label="Número de livros no acervo"
            id="acervo-total"
            type="number"
            min="0"
            step="1"
            value={totalAcervo}
            error={totalAcervo != 0 && totalAcervo != "" ? false : true}
            helperText={
              totalAcervo != 0 && totalAcervo != ""
                ? ""
                : "Indique o número aproximado de livros no acervo da biblioteca"
            }
            onChange={handleTotalAcervoChange}
          />
        </Grid>

        {!isEditing && <Grid className="buttons-grid" item xs={12}>
          <Button
            className="back-cancel-button"
            variant="outlined"
            onClick={handleBackScreen}
          >
            Voltar
            </Button>
          <Button
            className="submit-button"
            variant="outlined"
            onClick={handleFormValidation}
          >
            Avançar
            </Button>
        </Grid>
        }
      </Grid>
    </>
  );
}

export default LibraryRegisterAcervo;
