import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Link } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import UpdateIcon from "@material-ui/icons/Update";
import LinkIcon from "@material-ui/icons/Link";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import "./Forum.scss";

export default function ForumCard({ forum, showLibrary }) {
  const [createdDate, setCreatedDate] = useState(new Date());
  const [updatedDate, setUpdatedDate] = useState(new Date());
  useEffect(() => {
    if (forum.updatedAt) {
      setUpdatedDate(new Date(forum.updatedAt));
    } else {
      setUpdatedDate(new Date(forum.createdAt));
    }
    setCreatedDate(new Date(forum.createdAt));
  }, [forum]);
  return (
    <Card className={"forum-card"}>
      <CardContent style={{ paddingTop: 5, paddingBottom: 5 }}>
        {showLibrary ? (
          <>
            <Typography variant="h6" component="h6">
              {forum.title}
            </Typography>
            <Typography variant="body2" component="p">
              <LocalLibraryIcon /> &nbsp; {forum.createdBy}
            </Typography>
          </>
        ) : (
          <Typography className="forum-card-title">{forum.title}</Typography>
        )}

        <Typography variant="body2" component="p">
          <GroupIcon /> &nbsp;{`${forum.nParticipants} participantes`}
          &nbsp;&nbsp;
          <QuestionAnswerIcon /> &nbsp;{`${forum.nMessages} contribuições`}
        </Typography>
        <Typography variant="body2" component="p">
          <CalendarTodayIcon /> &nbsp;
          {`Criado em ${createdDate.getDate()}/${
            createdDate.getMonth() + 1
          }/${createdDate.getFullYear()}`}
        </Typography>
        <Typography variant="body2" component="p">
          <UpdateIcon /> &nbsp;
          {`Última contribuição em ${updatedDate.getDate()}/${
            updatedDate.getMonth() + 1
          }/${updatedDate.getFullYear()}`}
        </Typography>

        {!showLibrary && (
          <Typography variant="body2" component="p">
            <LinkIcon /> &nbsp;
            <Link href={`/forum/${forum._id}`}>Acessar Fórum</Link>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
