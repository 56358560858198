import React, { useState, useEffect, useContext } from "react";
import { Button, TextField, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import "./Rnbc.scss";
import Header from "../Header/Header";
import NotFound from "../Util/NotFound";
import { RootContext } from "../AppContext/AppContext";
import { sendEmailToLibrary } from "../../services/library";

const rnbcLogo = require("../../assets/images/RNBC-padrao.png");

export default function RNBC() {
  const { user } = useContext(RootContext);
  const [action, setAction] = useState("rnbc");
  const [selected, setSelected] = useState(0);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    message: "",
  });
  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
    success: { status: false, message: "" },
  });

  useEffect(() => {
    if (user && user.role != "anonymous") {
      let form = { ...contactForm };
      form.name = user.name;
      form.email = user.email;
      form.city = user.city;

      setContactForm(form);
    }
  }, []);

  async function handleSubmitMessage() {
    if (
      contactForm.name &&
      contactForm.email &&
      contactForm.city &&
      contactForm.message
    ) {
      let resp = await sendEmailToLibrary("rnbc", contactForm);
      if (resp.error) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message:
              "Houve um erro ao enviar sua mensagem. Por favor tente mais tarde",
          },
        });
      } else {
        setAlertStatus({
          ...alertStatus,
          success: {
            status: true,
            message: "Mensagem enviada com sucesso!",
          },
        });
      }
    } else {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message:
            "Mensagem não enviada. É necessário preencher todos campos para enviar a mensagem",
        },
      });
    }
  }

  return (
    <>
      <Header />
      <div className="rnbc-page">
        <div className="btn-grid">
          <Button
            variant="outlined"
            className={action === "rnbc" ? "tab-btn-selected" : "tab-btn"}
            onClick={(e) => setAction("rnbc")}
          >
            RNBC
          </Button>
          <Button
            variant="outlined"
            className={action === "cursos" ? "tab-btn-selected" : "tab-btn"}
            onClick={(e) => setAction("cursos")}
          >
            Cursos EAD
          </Button>
          <Button
            variant="outlined"
            className={action === "contact" ? "tab-btn-selected" : "tab-btn"}
            onClick={(e) => setAction("contact")}
          >
            Contato
          </Button>
        </div>
        {alertStatus.error.status && (
          <Alert
            style={{ marginTop: 5 }}
            severity="error"
            onClose={() => {
              setAlertStatus({ ...alertStatus, error: { status: false } });
            }}
          >
            {alertStatus.error.message}
          </Alert>
        )}
        {alertStatus.success.status && (
          <Alert
            style={{ marginTop: 5 }}
            severity="success"
            onClose={() => {
              setAlertStatus({ ...alertStatus, success: { status: false } });
            }}
          >
            {alertStatus.success.message}
          </Alert>
        )}
        {action === "rnbc" && (
          <Grid className="rbnc-info-section" container>
            <Grid item xs={12}>
              <img src={rnbcLogo} style={{ width: "20em" }} alt="image" />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} className="rnbc-info-text">
                <p>
                  A Rede Nacional de Bibliotecas Comunitárias (RNBC) é um
                  movimento formado por um coletivo de bibliotecas comunitárias
                  que visa a democratização do acesso ao livro, à leitura, à
                  literatura e às bibliotecas sob a perspectiva da leitura como
                  direito humano, com atuação em diversas cidades do território
                  brasileiro.
                </p>
                <p>
                  A RNBC conta atualmente com 11 Redes Locais e 119 Bibliotecas Comunitárias
                  nos estados do Pará, Maranhão, Ceará, Pernambuco, Bahia, Minas Gerais,
                  São Paulo, Rio de Janeiro e Rio Grande do Sul.
                </p>
                <p style={{ fontStyle: "italic" }}>
                  Conheça mais no site <a href="https://rnbc.org.br/" target="_blank">rnbc.org.br</a>
                </p>

                <h2>Bibliotecas Comunitárias</h2>
                <p>
                  Bibliotecas Comunitárias são espaços de incentivo à leitura que entrelaçam saberes
                  da educação e da cultura, surgem por iniciativa das comunidades e são gerenciadas por
                  elas ou, ainda que, embora não tenham sido iniciativas das próprias comunidades se voltam
                  para atendê-las e as incluem nos processos de planejamento, gestão, monitoramento e avaliação.
                </p>
              </Grid>
            </Grid>
          </Grid>
        )}
        {action === "cursos" && <NotFound msg={"Em construção"} />}
        {action === "contact" && (
          <Grid className="actions-container" container>
            <Grid
              style={{ marginTop: "10px" }}
              className="contact-menu"
              item
              xs={12}
            >
              <h3
                onClick={(e) =>
                  selected === 1 ? setSelected(0) : setSelected(1)
                }
                className={selected === 1 ? "mural-menu-selected" : ""}
              >
                Doar Livros
              </h3>
              <p style={{ display: selected === 1 ? "block" : "none" }}>
                As bibliotecas comunitárias do Mapa da Leitura estão localizadas
                em diversas cidades do país, localize a biblioteca mais próxima
                de você através do Mapa. Você pode entrar em contato direto com
                a biblioteca para fazer a doação.
              </p>
            </Grid>
            <Grid className="contact-menu" item xs={12}>
              <h3
                onClick={(e) =>
                  selected === 2 ? setSelected(0) : setSelected(2)
                }
                className={selected === 2 ? "mural-menu-selected" : ""}
              >
                Falar com assessoria de imprensa
              </h3>
              <p style={{ display: selected === 2 ? "block" : "none" }}>
                Encaminhe sua solicitação para&nbsp;
                <a href="mailto:comunicacao.leitura@gmail.com">
                  comunicacao.leitura@gmail.com
                </a>
                &nbsp;com cópia para&nbsp;
                <a href="mailto:comunicacaornbc@gmail.com">
                  comunicacaornbc@gmail.com
                </a>
              </p>
            </Grid>
            <Grid className="contact-menu" item xs={12}>
              <h3
                onClick={(e) =>
                  selected === 3 ? setSelected(0) : setSelected(3)
                }
                className={selected === 3 ? "mural-menu-selected" : ""}
              >
                Outros assuntos
              </h3>
              <p style={{ display: selected === 3 ? "block" : "none" }}>
                Preencha todos campos e clique em enviar para entrar em contato
                com a RNBC
              </p>
            </Grid>
            {selected === 3 && (
              <>
                <Grid
                  style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    id="name"
                    value={contactForm.name}
                    onChange={(e) =>
                      setContactForm({ ...contactForm, name: e.target.value })
                    }
                    label="Nome"
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid
                  style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    id="email"
                    value={contactForm.email}
                    onChange={(e) =>
                      setContactForm({ ...contactForm, email: e.target.value })
                    }
                    label="Email"
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid
                  style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    id="city"
                    value={contactForm.city}
                    onChange={(e) =>
                      setContactForm({ ...contactForm, city: e.target.value })
                    }
                    label="Cidade"
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid
                  style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    id="message"
                    value={contactForm.message}
                    onChange={(e) =>
                      setContactForm({
                        ...contactForm,
                        message: e.target.value,
                      })
                    }
                    rows="3"
                    multiline
                    label="Mensagem"
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid
                  className="btn-grid"
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingBottom: 30,
                  }}
                  item
                  xs={12}
                >
                  <Button
                    onClick={handleSubmitMessage}
                    fullWidth
                    variant="outlined"
                    className="send-msg-btn"
                  >
                    Enviar Mensagem
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </div>
    </>
  );
}
