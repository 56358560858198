import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, FormControlLabel } from "@material-ui/core";
import LibraryRegisterOficinas from "./LibraryRegisterOficinas";
import LibraryRegisterFormacoes from "./LibraryRegisterFormacoes";
import LibraryRegisterCursos from "./LibraryRegisterCursos";
import RegisterHeader from "./RegisterHeader";
import PurpleCheckbox from "../Util/PurpleCheckbox";

const actionsOptions = {
  mediacao: "Mediação de Leitura ou Contação de Histórias",
  oficinas: "Oficinas",
  clube: "Clube de Leitura",
  mala: "Mala de Leitura",
  encontro: "Encontro com escritor/A",
  sarau: "Sarau",
  formacoes: "Formações",
  feira: "Feira do Livro",
  cursos: "Cursos",
  reforco: "Reforço escolar",
  exposicoes: "Exposições",
  passeios: "Passeios culturais",
  roda: "Bate-papo ou roda de conversas",
  terapia: "Terapia comunitária",
  rodas: "Rodas de mulheres",
  apresentacoes: "Apresentações Artísticas",
  cinema: "Cinema",
};

function LibraryRegisterCulturalActions({
  libraryForm,
  handleOficinasUpdate,
  handleSubActionsUpdate,
  handleBackScreen,
  handleNextScreen,
  isEditing
}) {
  const [actionsFormData, setActionsFormData] = useState({
    mediacao: false,
    oficinas: false,
    clube: false,
    mala: false,
    encontro: false,
    sarau: false,
    formacoes: false,
    feira: false,
    cursos: false,
    reforco: false,
    exposicoes: false,
    passeios: false,
    roda: false,
    terapia: false,
    rodas: false,
    apresentacoes: false,
    cinema: false,
  });

  const [NextPartForm, setNextPartForm] = useState(0);
  const [screenSequence, setScreenSequence] = useState([0]);

  useEffect(() => {
    if(libraryForm.actions){
      let actions = [...libraryForm.actions.actions];
      let newActionsFormData = {
        mediacao: false,
        oficinas: false,
        clube: false,
        mala: false,
        encontro: false,
        sarau: false,
        formacoes: false,
        feira: false,
        cursos: false,
        reforco: false,
        exposicoes: false,
        passeios: false,
        roda: false,
        terapia: false,
        rodas: false,
        apresentacoes: false,
        cinema: false,
      };
  
      Object.keys(actionsOptions).forEach((key) => {
        let index = actions.indexOf(actionsOptions[key]);
        if (index != -1) {
          newActionsFormData[key] = true;
          actions.splice(index, 1);
        }
      });
  
      setActionsFormData(newActionsFormData);
    }
  }, []);

  const handleCheckboxChange = (event) => {
    let actions = {...actionsFormData};
    actions[event.target.name] = event.target.checked;
    setActionsFormData(actions);

    if(isEditing) updateEditActionsForm(actions);
  };

  const updateEditActionsForm = (actions) => {
    let subActions = [];
    for (let key in actions) {
      if (actions[key] === true) {
        subActions.push(actionsOptions[key]);
      }
    }
    handleSubActionsUpdate("actions", subActions);
  };

  const handleFormValidation = () => {
    let subActions = [];
    let newScreenSequence = [];
    for (let key in actionsFormData) {
      if (actionsFormData[key] === true) {
        subActions.push(actionsOptions[key]);
      }
    }
    handleSubActionsUpdate("actions", subActions);

    if (actionsFormData.oficinas) {
      newScreenSequence.push(1);
    }

    if (actionsFormData.formacoes) {
      newScreenSequence.push(2);
    }

    if (actionsFormData.cursos) {
      newScreenSequence.push(3);
    }

    if (newScreenSequence.length === 0) {
      handleNextScreen();
    }
    setScreenSequence(newScreenSequence);
    setNextPartForm(newScreenSequence[0]);
  };

  return (
    <>
    {!isEditing && <>{NextPartForm === 0 && <RegisterHeader title={"Ações Culturais"} />}
      {NextPartForm === 1 && <RegisterHeader title={"Oficinas"} />}
      {NextPartForm === 2 && <RegisterHeader title={"Formações"} />}
      {NextPartForm === 3 && <RegisterHeader title={"Cursos"} />}</>}
     
      
      <Grid className="checkbox-page" container style={{ marginTop: 5 }}>
        {NextPartForm === 0 && (
          <>
            {Object.keys(actionsOptions).map((key) => {
              return (
                <Grid item xs={12} key={key}>
                  <FormControlLabel
                    control={
                      <PurpleCheckbox
                        checked={actionsFormData[key]}
                        onChange={handleCheckboxChange}
                        name={key}
                      />
                    }
                    label={actionsOptions[key]}
                  />
                </Grid>
              );
            })}

            {!isEditing && <Grid className="buttons-grid" item xs={12}>
              <Button
                className="back-cancel-button"
                variant="outlined"
                onClick={handleBackScreen}
              >
                Voltar
              </Button>
              <Button
                className="submit-button"
                variant="outlined"
                onClick={handleFormValidation}
              >
                Avançar
              </Button>
            </Grid>}
          </>
        )}
        {/* TODO Olhar para variavel isEditing dentro de cada um*/}
        {isEditing && 
          <>
           <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
              <span className="form-helper-span">
                <b>Detalhes de Oficinas, formações e cursos</b>
              </span>
            </Grid>
            <LibraryRegisterOficinas
              setNextPartForm={setNextPartForm}
              oficinas={{
                oficinas: libraryForm.actions.oficinas,
                musicMore: libraryForm.actions.musicMore,
                instrMusicaisMore: libraryForm.actions.instrMusicaisMore,
                danceMore: libraryForm.actions.danceMore,
              }}
              screenSequence={screenSequence}
              handleOficinasUpdate={handleOficinasUpdate}
              handleSubActionsUpdate={handleSubActionsUpdate}
              handleNextScreen={handleNextScreen}
              isEditing={isEditing}
            />
            <LibraryRegisterFormacoes
              setNextPartForm={setNextPartForm}
              formacoesMore={libraryForm.actions.formacoesMore}
              screenSequence={screenSequence}
              handleSubActionsUpdate={handleSubActionsUpdate}
              handleNextScreen={handleNextScreen}
              isEditing={isEditing}
            />
            <LibraryRegisterCursos
              setNextPartForm={setNextPartForm}
              cursosMore={libraryForm.actions.cursosMore}
              screenSequence={screenSequence}
              handleSubActionsUpdate={handleSubActionsUpdate}
              handleNextScreen={handleNextScreen}
              isEditing={isEditing}
            />
          </>
        }
        {NextPartForm === 1 && (
          <LibraryRegisterOficinas
            setNextPartForm={setNextPartForm}
            oficinas={{
              oficinas: libraryForm.actions.oficinas,
              musicMore: libraryForm.actions.musicMore,
              instrMusicaisMore: libraryForm.actions.instrMusicaisMore,
              danceMore: libraryForm.actions.danceMore,
            }}
            screenSequence={screenSequence}
            handleOficinasUpdate={handleOficinasUpdate}
            handleSubActionsUpdate={handleSubActionsUpdate}
            handleNextScreen={handleNextScreen}
            isEditing={isEditing}
          />
        )}
        {NextPartForm === 2 && (
          <LibraryRegisterFormacoes
            setNextPartForm={setNextPartForm}
            formacoesMore={libraryForm.actions.formacoesMore}
            screenSequence={screenSequence}
            handleSubActionsUpdate={handleSubActionsUpdate}
            handleNextScreen={handleNextScreen}
            isEditing={isEditing}
          />
        )}
        {NextPartForm === 3 && (
          <LibraryRegisterCursos
            setNextPartForm={setNextPartForm}
            cursosMore={libraryForm.actions.cursosMore}
            screenSequence={screenSequence}
            handleSubActionsUpdate={handleSubActionsUpdate}
            handleNextScreen={handleNextScreen}
            isEditing={isEditing}
          />
        )}
      </Grid>
    </>
  );
}

export default LibraryRegisterCulturalActions;
