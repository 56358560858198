import React, { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { verifyToken } from "../../services/user";
import backgroundImage from "../../assets/images/login2-background.PNG";
import rnbcLogo from "../../assets/images/Logo-MapadaLeitura.png";
import "../Login/Login.scss";

function RequestResetPassword() {
  const [email, setEmail] = useState("");
  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
    success: { status: false, message: "" },
  });

  async function handleResetRequest() {
    if (email.length > 5) {
      let resp = await verifyToken(email);
      if (resp.error) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message: "Por favor indique um email válido",
          },
        });
      } else {
        setAlertStatus({
          ...alertStatus,
          success: {
            status: true,
            message:
              "Foram enviados as instruções para redefinição da senha para seu email",
          },
        });
      }
    } else {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message: "Por favor indique um email válido",
        },
      });
    }
  }

  return (
    <div className="login-root">
      <div className="login-page">
        <div
          className="background-img"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>

        <Grid container className="login-input-container">
          <Grid component={Link} to={"/"} item xs={12}>
            <img src={rnbcLogo} style={{ width: 200 }} />
          </Grid>
          <Grid item xs={12}>
            <h2>Mapa da Leitura</h2>
            <h2>Redefinição de senha</h2>
            {alertStatus.error.status && (
              <Alert
                style={{ marginTop: 5 }}
                severity="error"
                onClose={() => {
                  setAlertStatus({ ...alertStatus, error: { status: false } });
                }}
              >
                {alertStatus.error.message}
              </Alert>
            )}
            {alertStatus.success.status && (
              <Alert
                style={{ marginTop: 5 }}
                severity="success"
                onClose={() => {
                  setAlertStatus({
                    ...alertStatus,
                    success: { status: false },
                  });
                }}
              >
                {alertStatus.success.message}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <TextField
              className="create-forum-field"
              fullWidth
              id="outlined-email"
              label="Email"
              variant="outlined"
              style={{ background: "white" }}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <Button
              variant="outlined"
              onClick={handleResetRequest}
              fullWidth
              className="login-btn"
            >
              Enviar instruções
            </Button>
          </Grid>
          <Grid item xs={12} className="login-input-grid">
            <Button
              variant="outlined"
              component={Link}
              to={"/"}
              fullWidth
              className="no-login-btn"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default RequestResetPassword;
