import React, { useState, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import ForumDetail from "./ForumDetail";
import {
  Button,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  Toolbar,
  AppBar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import NotFound from "../Util/NotFound";
import Transition from "../Util/Transition";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { RootContext } from "../AppContext/AppContext";
import Loading from "../Util/Loading";
import Header from "../Header/Header";
import ForumCard from "./ForumCard";
import {
  getForum,
  getFollowingForuns,
  getInteractionsForuns,
  createForum,
} from "../../services/forum";
import "./Forum.scss";
const astronaut = require("../../assets/images/Astronaut.png");

function Forum() {
  const { user } = useContext(RootContext);
  let history = useHistory();
  const [ordering, setOrdering] = useState("date");
  const [creatingForum, setCreatingForum] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [ForumForm, setForumForm] = useState({
    title: "",
    description: "",
    message: "",
    createdBy: "",
    createdById: "",
    isLibrary: false,
    messages: [],
    participants: [],
  });

  const [allForuns, setAllForuns] = useState({
    fetched: false,
    foruns: [],
    skip: 0,
  });
  const [favoriteForuns, setFavoriteForuns] = useState({
    fetched: false,
    foruns: [],
    skip: 0,
  });
  const [interactionsForuns, setInteractionsForuns] = useState({
    fetched: false,
    foruns: [],
    skip: 0,
  });

  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
    success: { status: false, message: "" },
  });


  useEffect(() => {
    setIsFetching(true);
    handleGetForum();
  }, []);

  useEffect(() => {
    if (ordering != "date") {
      if (ordering === "favorites") {
        if (!favoriteForuns.fetched) {
          setIsFetching(true);
          handleGetForum();
        }
      } else {
        if (!interactionsForuns.fetched) {
          setIsFetching(true);
          handleGetForum();
        }
      }
    }
  }, [ordering]);

  useEffect(() => {
    if (allForuns.skip != 0) {
      handleGetForum();
    }
  }, [allForuns.skip]);

  useEffect(() => {
    if (favoriteForuns.skip != 0) {
      handleGetForum();
    }
  }, [favoriteForuns.skip]);

  useEffect(() => {
    if (interactionsForuns.skip != 0) {
      handleGetForum();
    }
  }, [interactionsForuns.skip]);

  function updateSkip() {
    if (ordering === "date") {
      let foruns = { ...allForuns, skip: allForuns.skip + 10 };
      setAllForuns(foruns);
    } else if (ordering === "favorites") {
      let foruns = { ...favoriteForuns, skip: favoriteForuns.skip + 10 };
      setFavoriteForuns(foruns);
    } else {
      let foruns = { ...interactionsForuns, skip: interactionsForuns.skip + 10 };
      setInteractionsForuns(foruns);
    }
  }

  async function handleGetForum() {
    let result;
    if (ordering === "date") {
      result = await getForum(null, allForuns.skip);
    } else if (ordering === "favorites") {
      result = await getFollowingForuns(user._id, favoriteForuns.skip);
    } else {
      result = await getInteractionsForuns(user._id, interactionsForuns.skip);
    }

    if (result.error) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message:
            "Houve um erro ao carregar os fóruns. Por favor tente mais tarde",
        },
      });
    } else {
      if (ordering === "date") {
        let foruns = { ...allForuns, fetched: true };
        foruns.foruns = allForuns.foruns.concat(result);
        setAllForuns(foruns);
      } else if (ordering === "favorites") {
        let foruns = { ...favoriteForuns, fetched: true };
        foruns.foruns = favoriteForuns.foruns.concat(result);
        setFavoriteForuns(foruns);
      } else {
        let foruns = { ...interactionsForuns, fetched: true };
        foruns.foruns = interactionsForuns.foruns.concat(result);
        setInteractionsForuns(foruns);
      }
      setIsFetching(false);
    }
  }

  async function handleCreateForum() {
    if (ForumForm.title.length === 0 || ForumForm.description.length === 0) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message:
            "Fórum não criado, é obrigatória a indicação de um nome e descrição para o fórum.",
        },
      });
    } else {
      let newForumForm = { ...ForumForm };
      newForumForm.createdBy = user.name;
      newForumForm.createdById = user._id;
      newForumForm.isLibrary = user.role != "user" ? true : false;
      newForumForm.participants = [user._id];

      if (ForumForm.message.length > 2) {
        newForumForm.messages = [
          {
            entityName: user.name,
            entityId: user._id,
            message: ForumForm.message,
            userRole: user.role,
          },
        ];
      }

      setForumForm(newForumForm);
      let resp = await createForum(newForumForm, user._id);
      if (resp.error) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message:
              "Houve um erro ao criar o Fórum. Por favor tente mais tarde",
          },
        });
      } else {
        history.push(`forum/${resp._id}`);
      }
    }
    setCreatingForum(false);
  }

  const { path, url } = useRouteMatch();
  return (
    <>
      <Dialog
        fullScreen
        open={creatingForum}
        onClose={(e) => setCreatingForum(false)}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative", backgroundColor: "#a73d8f" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => setCreatingForum(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="create-forum-title">
              Novo Fórum
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCreateForum}>
              Criar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container>
            <Grid className="create-forum-instructions" item xs={12}>
              <h3 style={{ textAlign: "center", marginTop: 0, marginBottom: 0 }}>
                Política de moderação
              </h3>
              <Typography variant="body2" color="textSecondary">
                O Mapa da Leitura reserva-se o direito de remover conteúdos que:
              </Typography>
              <Typography variant="body2" color="textSecondary" component="li">
                Difame ou viole direitos legais (como direitos de privacidade e publicidade) de outros.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="li">
                Conteúdos preconceituosos, que incitem o ódio e possuam cunho racista, machista, misógino, homofóbico, xenofóbico, gordofóbico ou de intolerância religiosa e social.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="li">
                Dissemine informação difamatória, infratora, obscena, indecente, enganosa ou ilegal.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="li">
                Carregar ou anexar arquivos que contenham vírus, arquivos corrompidos ou qualquer outro software ou programa semelhante que possa danificar a operação do computador de outro usuário.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="li">
                Falsificar a origem ou fonte do software ou outro material contido em um arquivo que é carregado.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="li">
                Spam ou links (URLs) promocionais e/ou comerciais ou temas que não se relacionam com o Mapa da Leitura.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <TextField
                fullWidth
                id="forum-title"
                error={ForumForm.title === ""}
                helperText={
                  ForumForm.title === ""
                    ? "É obrigatório a indicação de um titulo para o Fórum"
                    : ""
                }
                value={ForumForm.title}
                onChange={(e) =>
                  setForumForm({ ...ForumForm, title: e.target.value })
                }
                label="Nome do Fórum"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ marginTop: 10 }}
                fullWidth
                rows="2"
                required
                multiline
                id="Forum-description"
                value={ForumForm.description}
                onChange={(e) => {
                  setForumForm({ ...ForumForm, description: e.target.value });
                }}
                label="Descrição do Fórum"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ marginTop: 10 }}
                fullWidth
                rows="2"
                multiline
                id="message"
                value={ForumForm.message}
                onChange={(e) => {
                  setForumForm({ ...ForumForm, message: e.target.value });
                }}
                label="Mensagem Inicial"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} className="activities-btn-grid">
              <Button
                variant="outlined"
                className="cancel-btn"
                onClick={(e) => setCreatingForum(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="outlined"
                className="create-forum-btn-dialog"
                onClick={handleCreateForum}
              >
                Criar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Header />
      <div className="forum-page">
        {user && user.role != "anonymous" ? (
          <Switch>
            <Route exact path={path}>
              <Grid className="forum-header" container>
                <Grid item xs={12} className="forum-info-text">
                  Espaço aberto para trocas de ideias, informações e ajudas mútuas relacionadas
                  a Bibliotecas Comunitárias e ao universo do livro, sendo um canal de aproximação
                  entre bibliotecas e leitoras/es.
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", paddingTop: "10px" }}
                >
                  <Button
                    onClick={(e) => setCreatingForum(true)}
                    variant="outlined"
                    className="create-btn"
                  >
                    Adicionar novo Fórum
                  </Button>
                  {alertStatus.error.status && (
                    <Alert
                      style={{ marginTop: 5 }}
                      severity="error"
                      onClose={() => {
                        setAlertStatus({
                          ...alertStatus,
                          error: { status: false },
                        });
                      }}
                    >
                      {alertStatus.error.message}
                    </Alert>
                  )}
                  {alertStatus.success.status && (
                    <Alert
                      style={{ marginTop: 5 }}
                      severity="success"
                      onClose={() => {
                        setAlertStatus({
                          ...alertStatus,
                          success: { status: false },
                        });
                      }}
                    >
                      {alertStatus.success.message}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <h2 className="foruns-list"> Lista de fóruns</h2>
                </Grid>
                <Grid item xs={12} className="forum-sort-by" style={{ marginLeft: 20 }}>
                  <Typography
                    // className="info-text"
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Ordenar por:
                  </Typography>
                </Grid>
                <Grid item xs={12} className="btn-grid">
                  <Button
                    variant="outlined"
                    className={
                      ordering === "date" ? "tab-btn-selected" : "tab-btn"
                    }
                    onClick={(e) => setOrdering("date")}
                  >
                    Data
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      ordering === "favorites" ? "tab-btn-selected" : "tab-btn"
                    }
                    onClick={(e) => setOrdering("favorites")}
                  >
                    Favoritas
                  </Button>
                  <Button
                    variant="outlined"
                    className={
                      ordering === "interaction"
                        ? "tab-btn-selected"
                        : "tab-btn"
                    }
                    onClick={(e) => setOrdering("interaction")}
                  >
                    Interação
                  </Button>
                </Grid>
              </Grid>
              {isFetching ? (
                <Loading
                  msg={
                    "Carregando Informações. Por favor aguarde um instantinho"
                  }
                />
              ) : (
                  <Grid container>
                    {ordering === "date" ? (
                      allForuns.foruns.length > 0 ? (
                        <>
                          { allForuns.foruns.map((forum, key) => (
                            <Grid item xs={12} lg={4} key={key}>
                              <Link
                                key={key}
                                to={`${url}/${forum._id}`}
                                className="card-link"
                              >
                                <ForumCard forum={forum} showLibrary={true} />
                              </Link>
                            </Grid>
                          ))
                          }
                          {allForuns.foruns.length >= 10 &&
                            <Grid item xs={12} className="load-more-forum">
                              <Button className="load-more-button" onClick={updateSkip}>Carregar mais fóruns</Button>
                            </Grid>
                          }
                        </>
                      ) : (
                          <NotFound
                            msg={"Não foram encontrados Fóruns cadastrados ainda"}
                          />
                        )
                    ) : (
                        ""
                      )}

                    {ordering === "favorites" ? (
                      favoriteForuns.foruns.length > 0 ? (
                        <>
                          {
                            favoriteForuns.foruns.map((forum, key) => (
                              <Grid item xs={12} lg={4} key={key}>
                                <Link
                                  key={key}
                                  to={`${url}/${forum._id}`}
                                  className="card-link"
                                >
                                  <ForumCard forum={forum} showLibrary={true} />
                                </Link>
                              </Grid>
                            ))
                          }
                          {favoriteForuns.foruns.length >= 10 &&
                            <Grid item xs={12}>
                              <Button onClick={updateSkip}>Carregar mais fóruns</Button>
                            </Grid>
                          }
                        </>
                      ) : (
                          <NotFound
                            msg={
                              "As bibliotecas que você segue ainda não criaram nenhum fórum"
                            }
                          />
                        )
                    ) : (
                        ""
                      )}

                    {ordering === "interaction" ? (
                      interactionsForuns.foruns.length > 0 ? (
                        <>
                          {
                            interactionsForuns.foruns.map((forum, key) => (
                              <Grid item xs={12} lg={4} key={key}>
                                <Link
                                  key={key}
                                  to={`${url}/${forum._id}`}
                                  className="card-link"
                                >
                                  <ForumCard forum={forum} showLibrary={true} />
                                </Link>
                              </Grid>
                            ))
                          }
                          {interactionsForuns.foruns.length >= 10 &&
                            <Grid item xs={12}>
                              <Button onClick={updateSkip}>Carregar mais fóruns</Button>
                            </Grid>
                          }
                        </>
                      ) : (
                          <NotFound
                            msg={
                              "Você ainda não criou ou interagiu em nenhum fórum"
                            }
                          />
                        )
                    ) : (
                        ""
                      )}
                  </Grid>
                )}
            </Route>

            <Route path={`${path}/:id`}>
              <ForumDetail />
            </Route>
          </Switch>
        ) : (
            <Grid
              style={{
                textAlign: "center",
                paddingTop: 25,
                paddingLeft: 15,
                paddingRight: 15,
              }}
              container
            >
              <Grid item xs={12}>
                <img src={astronaut} style={{ width: 250 }} alt="image" />
              </Grid>
              <Grid
                style={{
                  textAlign: "center",
                  fontFamily: "Syne",
                  color: "#a73d8f",
                  letterSpacing: "0.025em",
                }}
                item
                xs={12}
              >
                <h3 style={{ marginBottom: 0, marginTop: 0 }}>
                  Para acompanhar e participar dos fóruns do Mapa da Leitura é
                  necessário realizar login ou cadastrar-se. É rápido, simples e
                  totalmente gratuito.
              </h3>
              </Grid>
              <Grid
                style={{ textAlign: "center" }}
                className="activities-btn-grid"
                item
                xs={12}
              >
                <Button
                  variant="outlined"
                  className="cancel-btn"
                  style={{ minWidth: 107 }}
                  onClick={(e) => history.push("/user-register")}
                >
                  Cadastro
              </Button>
                <Button
                  variant="outlined"
                  className="cancel-btn"
                  style={{ minWidth: 107 }}
                  onClick={(e) => history.push("/login-user")}
                >
                  Login
              </Button>
              </Grid>
            </Grid>
          )}
      </div>
    </>
  );
}

export default Forum;
