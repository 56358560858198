import React, { useState } from "react";
import LibraryRegisterIntro from "./LibraryRegisterIntro";
import LibraryRegisterGeneralForm from "./LibraryRegisterGeneralForm";
import LibraryRegisterAccessibility from "./LibraryRegisterAccessibility";
import LibraryRegisterCulturalActions from "./LibraryRegisterCulturalActions";
import LibraryRegisterAcervo from "./LibraryRegisterAcervo";
import LibraryRegisterPartners from "./LibraryRegisterPartners";
import RegisterConfirmation from "./RegisterConfirmation";
import Header from "../Header/Header";
import { createLibrary } from "../../services/library";
import { getLatLng } from "../../services/location";
import "./LibraryRegister.scss";

function LibraryRegister() {
  const [screen, setScreen] = useState(0);
  const [libraryForm, setLibraryForm] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "library",
    phones: "",
    workingTime: "",
    rnbcNews: false,
    team: "",
    institution: [],
    libCreationDate: "",
    facebook: "",
    instagram: "",
    address: {
      zipCode: "",
      city: "",
      state: "",
      addressLine: "",
    },
    location: { type: "Point", coordinates: [-43.8160646, -19.8932649] },
    actions: {
      actions: [],
      danceMore: [],
      oficinas: [],
      musicMore: [],
      instrMusicaisMore: [],
      formacoesMore: [],
      cursosMore: [],
    },
    partners: [],
    acervo: { acervo: [], total: 0, acervoGeralMore: [] },
    accessibility: [],
  });

  async function handleFormUpdate(key, value) {
    if (key === "accessibility") {
      let location = await getLatLng(
        libraryForm.address.zipCode,
        libraryForm.address.addressLine,
        libraryForm.address.state
      );

      let newLocation = {
        type: "Point",
        coordinates: [location.lng, location.lat],
      };
      setLibraryForm({ ...libraryForm, accessibility: value, location: newLocation });
    } else {
      setLibraryForm({ ...libraryForm, [key]: value });
    }
  }

  function handleNextScreen() {
    setScreen(screen + 1);
  }

  function handleBackScreen() {
    if (screen - 1 > 0) {
      setScreen(screen - 1);
    }
  }

  function handleSubActionsUpdate(key, value) {
    let newActions = { ...libraryForm.actions };
    newActions[key] = value;
    setLibraryForm({ ...libraryForm, actions: newActions });
  }

  const handleOficinasUpdate = (obj) => {
    let newActions = { ...libraryForm.actions };
    newActions.oficinas = obj.oficinas;
    newActions.musicMore = obj.musicMore;
    newActions.instrMusicaisMore = obj.instrMusicaisMore;
    newActions.danceMore = obj.danceMore;
    setLibraryForm({ ...libraryForm, actions: newActions });
  };

  async function handleFormSubmit(partners) {
    let libraryData = { ...libraryForm };
    libraryData.partners = partners;
    let library = await createLibrary(libraryData);
    if (library.error) {
      alert("Houve um erro, por favor tente mais tarde");
    } else {
      setScreen(6);
    }
  }

  return (
    <>
      <Header />
      <div className="library-register-page">
        {screen === 0 && (
          <LibraryRegisterIntro handleNextScreen={handleNextScreen} />
        )}
        {screen === 1 && (
          <LibraryRegisterGeneralForm
            libraryForm={libraryForm}
            handleFormUpdate={handleFormUpdate}
            handleNextScreen={handleNextScreen}
          />
        )}
        {screen === 2 && (
          <LibraryRegisterAccessibility
            libraryForm={libraryForm}
            handleFormUpdate={handleFormUpdate}
            handleBackScreen={handleBackScreen}
            handleNextScreen={handleNextScreen}
          />
        )}
        {screen === 3 && (
          <LibraryRegisterCulturalActions
            libraryForm={libraryForm}
            handleSubActionsUpdate={handleSubActionsUpdate}
            handleBackScreen={handleBackScreen}
            handleOficinasUpdate={handleOficinasUpdate}
            handleNextScreen={handleNextScreen}
          />
        )}
        {screen === 4 && (
          <LibraryRegisterAcervo
            acervo={libraryForm.acervo}
            handleFormUpdate={handleFormUpdate}
            handleBackScreen={handleBackScreen}
            handleNextScreen={handleNextScreen}
          />
        )}
        {screen === 5 && (
          <LibraryRegisterPartners
            libraryForm={libraryForm}
            handleFormUpdate={handleFormUpdate}
            handleBackScreen={handleBackScreen}
            handleFormSubmit={handleFormSubmit}
          />
        )}
        {screen === 6 && (
          <RegisterConfirmation
            name={libraryForm.name}
            email={libraryForm.email}
          />
        )}
      </div>
    </>
  );
}

export default LibraryRegister;
