import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
export const RootContext = React.createContext();

function b64Decode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

function b64Encode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
      match,
      p1
    ) {
      return String.fromCharCode("0x" + p1);
    })
  );
}

const AppContext = ({ children }) => {
  let prevAuth = Cookies.get("map_access_token") || null;
  if (prevAuth) {
    prevAuth = JSON.parse(b64Decode(prevAuth));
  }
  const [user, setUser] = useState(prevAuth);

  function doLogout() {
    Cookies.remove("map_access_token");
    setUser(null);
  }

  useEffect(() => {
    async function authenticate() {
      if (user) {
        Cookies.set("map_access_token", b64Encode(JSON.stringify(user)));
      }
    }
    authenticate();
  }, [user]);

  const defaultContext = {
    user,
    setUser,
    doLogout,
  };

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
};

export default AppContext;
