import React from "react";
import RegisterHeader from "./RegisterHeader";
import { Button, Grid } from "@material-ui/core";

function LibraryRegisterIntro({ handleNextScreen }) {
  return (
    <>
      <RegisterHeader title={"O que são bibliotecas comunitárias?"} />
      <Grid container>
        <Grid item xs={12} className="header-body">
          <p>
            A RNBC conceitua bibliotecas comunitárias como espaços de incentivo
            à leitura que entrelaçam saberes da Arte, da Educação e da Cultura,
            criados por iniciativa das comunidades e que são gerenciadas por
            elas ou, ainda, espaços que, embora não tenham sido iniciativas das
            próprias comunidades, se voltam para atendê-las e as incluem nos
            processos de planejamento, gestão, monitoramento e avaliação. Uma
            biblioteca comunitária é caracterizada pelo seu uso público e
            comunitário, tendo como princípio fundamental a participação de seu
            público nos processos de gestão compartilhada.
          </p>
          <p style={{ fontStyle: "italic", textAlign: "right" }}>
            Regimento Interno RNBC, 2019
          </p>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <Button
            fullWidth
            className="submit-button"
            onClick={(e) => handleNextScreen()}
          >
            Cadastrar Biblioteca
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default LibraryRegisterIntro;
