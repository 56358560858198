import React from "react";
import { Grid } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import UILink from '@material-ui/core/Link';
import "./TopNavigation.scss";
const rnbcLogo = require("../../assets/images/Logo-MapadaLeitura.png");

export default function TopNavigation() {
    const routesWithoutNav = [
        "/",
        "/login-user",
        "/user-register",
        "/login-library",
        "/library-register",
    ];
    let location = useLocation();

    return (
        <>
            {!location.pathname.includes("forgot") && !routesWithoutNav.includes(location.pathname) && (
                <Grid
                    className="top-navigation"
                    container
                >
                    <Grid component={Link} to={"/"} item lg={2}>
                        <img src={rnbcLogo} style={{ width: 150 }} />
                    </Grid>
                    {/* <Grid
                    className="navigation-title"
                    item
                    lg={6}
                    style={{
                    lineHeight: "2em",
                    fontSize: "1.4em",
                    }}
                >
                    Mapa da Leitura
                </Grid> */}
                    <Grid
                        className="menu-grid"
                        item
                        style={{ textAlign: "right" }}
                        lg={10}
                    >
                        <UILink href="/map" color="inherit">
                            Mapa de Bibliotecas
                    </UILink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <UILink href="/activities" color="inherit">
                            Atividades
                    </UILink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <UILink href="/forum" color="inherit">
                            Fórum
                    </UILink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <UILink href="/rnbc" color="inherit">
                            Informações
                    </UILink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <UILink href="/profile" color="inherit">
                            Meu perfil
                    </UILink>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
