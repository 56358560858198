import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, Card, CardContent, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Header from "../Header/Header";
import { getUserEvents, getUserInfo } from "../../services/user";
import { RootContext } from "../AppContext/AppContext";
import Loading from "../Util/Loading";
import NotFound from "../Util/NotFound";
import EventCard from "../Util/EventCard";
import ForumCard from "../Forum/ForumCard";
import "./Activities.scss";

export default function ActivitiesUser() {
  const { user } = useContext(RootContext);
  const [activities, setActivities] = useState("newEvents");
  const [agendaFilter, setAgendaFilter] = useState("next");
  const [isFetching, setIsFetching] = useState(false);
  const [userInfo, setUserInfo] = useState({
    fetched: false,
    following: [],
    foruns: [],
  });
  const [events, setEvents] = useState({
    next: [],
    all: [],
    past: [],
    newEvents: [],
    fetched: false,
  });
  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
  });

  useEffect(() => {
    if (activities === "newEvents" && !events.fetched) {
      setIsFetching(true);
      fetchUserEvents();
    } else if (activities === "agenda" && !events.fetched) {
      setIsFetching(true);
      fetchUserEvents();
    } else if (activities === "forum" && !userInfo.fetched) {
      setIsFetching(true);
      fetchUserInfo();
    }
  }, [activities]);

  async function fetchUserInfo() {
    let infos = await getUserInfo(user._id);
    if (infos.error) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message:
            "Houve um erro ao acessar informações sobre os eventos. Por favor tente mais tarde",
        },
      });
    } else {
      infos["fetched"] = true;
      setUserInfo(infos);
      setIsFetching(false);
    }
  }

  function fetchUserEvents() {
    getUserEvents(user._id)
      .then((response) => response.json())
      .then((data) => {
        data["fetched"] = true;
        setEvents(data);
        setIsFetching(false);
      })
      .catch((error) => {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message:
              "Houve um erro ao acessar as informações das bibliotecas que você segue",
          },
        });
        setIsFetching(false);
      });
  }

  return (
    <>
      <Header />
      <div className="activities-page">
        <div className={"btn-grid"} style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            style={{ minWidth: 111 }}
            className={
              activities === "newEvents" ? "tab-btn-selected" : "tab-btn"
            }
            onClick={(e) => setActivities("newEvents")}
          >
            Novidades
          </Button>
          <Button
            variant="outlined"
            style={{ minWidth: 111 }}
            className={activities === "agenda" ? "tab-btn-selected" : "tab-btn"}
            onClick={(e) => setActivities("agenda")}
          >
            Agenda
          </Button>
          <Button
            variant="outlined"
            style={{ minWidth: 111 }}
            className={activities === "forum" ? "tab-btn-selected" : "tab-btn"}
            onClick={(e) => setActivities("forum")}
          >
            Fóruns
          </Button>
        </div>
        {alertStatus.error.status && (
          <Alert
            style={{ marginTop: 5 }}
            severity="error"
            onClose={() => {
              setAlertStatus({ ...alertStatus, error: { status: false } });
            }}
          >
            {alertStatus.error.message}
          </Alert>
        )}
        {isFetching ? (
          <Loading msg={"Carregando Informações de usuário"} />
        ) : (
            <>
              {activities === "newEvents" ? (
                events.newEvents.length > 0 ? (
                  events.newEvents.map((event, key) => (
                    <EventCard
                      event={event}
                      isLibrary={false}
                      key={key + "newEvents"}
                    />
                  ))
                ) : (
                    <NotFound
                      msg={
                        "Você não possui nenhuma novidade das bibliotecas que segue"
                      }
                    />
                  )
              ) : (
                  ""
                )}
              {activities === "agenda" && (
                <div className="agenda" style={{ textAlign: "center" }}>
                  <span className="events-filter-label">
                    Selecione abaixo o filtro para exibição dos eventos:
                  </span>

                  <div className={"btn-grid"} style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      className={
                        agendaFilter === "next" ? "tab-btn-selected" : "tab-btn"
                      }
                      onClick={(e) => setAgendaFilter("next")}
                    >
                      Próximos
                  </Button>
                    <Button
                      variant="outlined"
                      className={
                        agendaFilter === "past" ? "tab-btn-selected" : "tab-btn"
                      }
                      onClick={(e) => setAgendaFilter("past")}
                    >
                      Passados
                  </Button>
                  </div>
                  <Grid style={{ display: "inline-flex" }} container>
                    {events[agendaFilter].length > 0 ? (
                      events[agendaFilter].map((event, key) => (
                        <Grid item xs={12} lg={4} key={key}>
                          <EventCard
                            event={event}
                            isLibrary={false}
                            key={key + "agenda"}
                          />
                        </Grid>
                      ))
                    ) : (
                        <NotFound
                          msg={
                            "Não constam eventos das bibliotecas que você segue com os filtros indicados"
                          }
                        />
                      )}
                  </Grid>
                </div>
              )}
            </>
          )}
        {activities === "forum" && (
          <Grid container>
            <Grid
              style={{ marginTop: "10px" }}
              className="mural-menu"
              item
              xs={12}
            >
              <Grid style={{ display: "inline-flex" }} container>
                {userInfo.foruns.length > 0 ?
                  (userInfo.foruns.map((forum, key) => (
                    <Grid item xs={12} lg={4} key={key}>
                      <ForumCard key={key} forum={forum} />
                    </Grid>
                  )))
                  : (<NotFound msg={"Você não criou ou interagiu com nenhum fórum ainda"} />)
                }
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}
