import React from "react";

export default function RegisterHeader({ title }) {
  return (
    <div className="header-intro">
      <h3>Cadastro de Biblioteca</h3>
      <h2>{title}</h2>
    </div>
  );
}
