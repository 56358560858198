import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { RootContext } from "../AppContext/AppContext";
import { Alert } from "@material-ui/lab";
import EventIcon from "@material-ui/icons/Event";
import LinkIcon from "@material-ui/icons/Link";
import DescriptionIcon from "@material-ui/icons/Description";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteEvent } from "../../services/library";
import "./EventCard.scss";

// Tenho que olhar para a variavel isLibrary para definir se tenho a opção de excluir
export default function EventCard({ event, isLibrary }) {
  const { user } = useContext(RootContext);
  const [date, setDate] = useState(new Date());
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
  });

  useEffect(() => {
    setDate(new Date(event.datetime));
  }, [event]);

  async function handleEventDelete() {
    let resp = await deleteEvent(event.libraryId, event._id, user.password);
    if (resp.message) {
    } else {
      window.location.reload();
    }
    setDeleteDialog(false);
  }

  return (
    <>
      {isLibrary && (
        <Dialog
          open={deleteDialog}
          onClose={(e) => setDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Apagar Evento?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Você tem certeza que deseja apagar o evento <b>{event.name}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setDeleteDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleEventDelete} color="primary" autoFocus>
              Apagar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {alertStatus.error.status && (
        <Alert
          style={{ marginTop: 5 }}
          severity="error"
          onClose={() => {
            setAlertStatus({ ...alertStatus, error: { status: false } });
          }}
        >
          {alertStatus.error.message}
        </Alert>
      )}
      <Card className={"activity-card"}>
        <CardContent>
          <Typography
            style={{ fontSize: 14 }}
            color="textSecondary"
            gutterBottom
          >
            Novo evento
            {isLibrary && (
              <DeleteIcon
                onClick={(e) => setDeleteDialog(true)}
                style={{ float: "right" }}
              />
            )}
          </Typography>

          <Typography variant="h5" component="h3">
            {isLibrary ? event.name : event.libraryName}
          </Typography>
          {!isLibrary && (
            <Typography variant="body2" component="p">
              <LocalLibraryIcon /> &nbsp; {event.name}
            </Typography>
          )}
          <Typography variant="body2" component="p">
            <EventIcon /> &nbsp;{" "}
            {`${date.getDate()}/${date.getMonth() + 1
              }/${date.getFullYear()} às ${date.getHours()}:${date.getMinutes()}h`}
          </Typography>
          {event.description && (
            <Typography variant="body2" component="p">
              <DescriptionIcon /> &nbsp; {event.description}
            </Typography>
          )}
          {event.link && (
            <Typography variant="body2" component="p">
              <LinkIcon /> &nbsp;
              <Link href="#" onClick={(e) => window.open(event.link)}>
                Acessar evento
              </Link>
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
}
