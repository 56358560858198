export function createUser(user) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/user";

  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        return { error: true };
      });
    return res;
  } catch (error) {
    return { error: true };
  }
}

export function followLibrary(libraryId, userId, follow) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/library/follow";
  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        libraryId: libraryId,
        userId: userId,
        follow: follow,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Não foi possível completar solicitação");
        }
        return {};
      })
      .catch(function (error) {
        return { error: true };
      });
    return res;
  } catch (error) {
    return { error: true };
  }
}

export function getUserById(userId) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/user/" + userId;

  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error in get User");
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });
    return resp;
  } catch (error) {
    return { error: true };
  }
}

export function getEmailExists(email) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/email-exists/" + email;

  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error in get User");
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });
    return resp;
  } catch (error) {
    return { error: true };
  }
}

export function getUserInfo(userId) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/user/${userId}/activities-info`;

  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error in get user info");
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });
    return resp;
  } catch (error) {
    return { error: true };
  }
}

export function userAuth(credentials) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/login";

  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.message) {
          throw new Error(res.message);
        }
        return res;
      })
      .catch(function (error) {
        return { error: error };
      });
    return res;
  } catch (error) {
    return { error: error };
  }
}

export function checkResetToken(token, entityId) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/forgot/${token}/${entityId}`;

  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        return resp;
      })
      .catch(function (error) {
        return { error: error };
      });
    return resp;
  } catch (error) {
    return { error: error };
  }
}

export function verifyToken(email) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/forgot/" + email;

  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.message) {
          throw new Error(res.message);
        }
        return res;
      })
      .catch(function (error) {
        return { error: error };
      });
    return res;
  } catch (error) {
    return { error: error };
  }
}

//POST para definir novo password
export function resetPassword(token, entityId, password) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/forgot/${token}/${entityId}`;

  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: password }),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.message) {
          throw new Error(res.message);
        }
        return res;
      })
      .catch(function (error) {
        return { error: error };
      });
    return res;
  } catch (error) {
    return { error: error };
  }
}

export function getUserEvents(userId) {
  let fetchUrl =
    process.env.REACT_APP_BACKEND_URL + "/user/" + userId + "/event";

  return fetch(fetchUrl);
}

export function deleteEntity(entityId, token, reasonToDelete) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/entity/${entityId}`;

  try {
    let res = fetch(fetchUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token, reasonToDelete: reasonToDelete }),
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        return resp;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } catch (error) {
    return error;
  }
}