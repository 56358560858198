import React, { useEffect, useState, useContext } from "react";
import { getForum, addForumMessage } from "../../services/forum";
import { getUserById } from "../../services/user";
import Loading from "../Util/Loading";
import { Grid, TextField, Button, Dialog, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText, DialogContent, DialogActions } from "@material-ui/core";
import ReplyIcon from '@material-ui/icons/Reply';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Alert } from "@material-ui/lab";
import { RootContext } from "../AppContext/AppContext";
import "./Forum.scss";
import { useParams, useHistory } from "react-router-dom";
import ForumMessage from "./ForumMessage";
import FollowingCard from "../Activities/FollowingCard";

function ForumDetail() {
  let history = useHistory();
  const { id } = useParams();
  const { user } = useContext(RootContext);
  const [forum, setForum] = useState({
    title: "",
    description: "",
    createdBy: "",
    createdAt: "",
    isLibrary: true,
    messages: [],
  });
  const [newMessage, setNewMessage] = useState({
    entityName: user.name,
    message: "",
    entityId: user._id,
    userRole: user.role
  });
  const [showForumInfo, setShowForumInfo] = useState(true);
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [messageOption, setMessageOption] = useState({ showModal: false, showReplyModal: false, replyMessage: "" });
  const [messageUser, setMessageUser] = useState({ showModal: false, user: { address: {}, following: [] } })
  const [isFetching, setIsFetching] = useState(true);

  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
  });

  useEffect(() => {
    handleGetForum();
  }, []);

  async function handleGetForum() {
    let resp = await getForum(id);
    if (resp.error || resp.length === 0) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message:
            "Houve um erro ao carregar o Fórum. Por favor tente mais tarde",
        },
      });
    } else {
      resp[0].messages.reverse();
      setForum(resp[0]);
    }
    setIsFetching(false);
  }

  async function handleSendMessageClick() {
    if (showMessageForm) {
      let resp = await addForumMessage(id, newMessage);
      if (resp.error) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message:
              "Houve um erro ao enviar sua mensagem. Por favor tente mais tarde",
          },
        });
      } else {
        resp.reverse();
        setForum({ ...forum, messages: resp });
        setShowMessageForm(false);
        setNewMessage({ ...newMessage, message: "" });
      }
    } else {
      setShowMessageForm(true);
      setShowForumInfo(false);
    }
  }

  function onMessageClick(message) {
    setMessageOption({ showModal: true, replyTo: message.entityName, replytoId: message.entityId, userRole: message.userRole })
  }

  async function onViewProfileClick() {
    if (messageOption.userRole === "library" || messageOption.userRole === "libraryRnbc") {
      history.push("/library/" + messageOption.replytoId);
    } else {
      let msgUsr = await getUserById(messageOption.replytoId);
      if (msgUsr.error) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message:
              "Erro ao pesquisar perfil de usuário",
          },
        });
      } else {
        setMessageOption({ showModal: false });
        setMessageUser({ showModal: true, user: msgUsr });
      }
    }
  }

  function onReplyClick() {
    let newMsgOption = { ...messageOption }
    newMsgOption.showModal = false;
    newMsgOption.showReplyModal = true;
    setMessageOption(newMsgOption);
  }

  async function sendReplyMessage() {
    setMessageOption({ showReplyModal: false });
    let msg = { ...newMessage };
    msg.message = messageOption.replyMessage;
    msg.replyTo = messageOption.replyTo;
    msg.replytoId = messageOption.replytoId;
    let resp = await addForumMessage(id, msg);
    if (resp.error) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message:
            "Houve um erro ao enviar sua mensagem. Por favor tente mais tarde",
        },
      });
    } else {
      resp.reverse();
      setForum({ ...forum, messages: resp });
      setShowMessageForm(false);
      setNewMessage({ ...newMessage, message: "" });
    }
  }

  return (
    <div className="forum-detail-page">
      <Dialog fullWidth={"md"} open={messageUser.showModal} onClose={(e) => setMessageUser({ showModal: false, user: { address: {}, following: [] } })}>
        <FollowingCard entity={messageUser.user} isDetailed={true} userId={user._id} />
      </Dialog>
      <Dialog open={messageOption.showReplyModal} fullWidth={"md"} onClose={(e) => setMessageOption({ showModal: false, showReplyModal: false })} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Responder a @{messageOption.replyTo}</DialogTitle>
        <DialogContent>
          <TextField
            style={{
              marginTop: 10,
            }}
            fullWidth
            rows="6"
            multiline
            id="message"
            value={messageOption.replyMessage}
            onChange={(e) => {
              setMessageOption({ ...messageOption, replyMessage: e.target.value });
            }}
            label="Enviar mensagem ao fórum"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setMessageOption({ showModal: false, showReplyModal: false })} color="primary">
            Cancelar
          </Button>
          <Button onClick={sendReplyMessage} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={(e) => setMessageOption({ showModal: false })} aria-labelledby="simple-dialog-title" open={messageOption.showModal}>
        <DialogTitle id="simple-dialog-title">Selecione a ação</DialogTitle>
        <List>
          <ListItem autoFocus button onClick={onReplyClick}>
            <ListItemAvatar>
              <Avatar>
                <ReplyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Responder comentário" />
          </ListItem>
          <ListItem autoFocus button onClick={onViewProfileClick}>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Ver Perfil" />
          </ListItem>
        </List>
      </Dialog>
      {!isFetching ? (
        <>
          <div className="forum-detail-header">
            <h4>{forum.title}</h4>
            <span>
              Criado por <i>{forum.createdBy}</i>
            </span>
          </div>
          {alertStatus.error.status && (
            <Alert
              style={{ marginTop: 5 }}
              severity="error"
              onClose={() => {
                setAlertStatus({
                  ...alertStatus,
                  error: { status: false },
                });
              }}
            >
              {alertStatus.error.message}
            </Alert>
          )}
          <Grid container>
            <Grid
              style={{ marginTop: "10px" }}
              className="mural-menu"
              item
              xs={12}
            >
              <h3
                onClick={(e) => setShowForumInfo(!showForumInfo)}
                className={showForumInfo ? "mural-menu-selected" : ""}
              >
                Informações do Fórum
              </h3>
              <div
                style={{ display: showForumInfo ? "block" : "none" }}
                className="forum-detail-description"
              >
                {forum.description}
                <br />
                {forum.createdAt && (
                  <i>{`Criado em ${new Date(forum.createdAt).getDate()}/${new Date(forum.createdAt).getUTCMonth() + 1
                    }/${new Date(forum.createdAt).getUTCFullYear()}`}</i>
                )}
              </div>
            </Grid>
            <Grid style={{ paddingLeft: 10, paddingRight: 10 }} item xs={12}>
              <TextField
                style={{
                  marginTop: 10,
                  display: showMessageForm ? "block" : "none",
                }}
                fullWidth
                rowsMin="2"
                multiline
                id="message"
                value={newMessage.message}
                onChange={(e) => {
                  setNewMessage({ ...newMessage, message: e.target.value });
                }}
                label="Enviar Mensagem ao Fórum"
                variant="outlined"
              />
              <Button
                style={{ marginTop: 10 }}
                fullWidth
                className="send-message-btn"
                onClick={handleSendMessageClick}
              >
                Enviar mensagem
              </Button>
            </Grid>
            {forum.messages.map((message, key) => (
              <Grid item xs={12}>
                <div onClick={user._id === message.entityId ? () => { } : (e) => onMessageClick(message)}>
                  <ForumMessage
                    key={message._id}
                    classToApply={
                      key % 2 === 0 ? "forum-message-even" : "forum-message-odd"
                    }
                    message={message}
                    forumId={forum._id}
                    isOwner={user._id === message.entityId ? true : false}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
          <Loading msg={"Carregando informações do Fórum"} />
        )
      }
    </div >
  );
}

export default ForumDetail;
