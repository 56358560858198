import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, Typography, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import { RootContext } from "../AppContext/AppContext";
import { deleteForumMessage, updateForumMessage } from "../../services/forum";

export default function ForumMessage({ message, classToApply, forumId, isOwner }) {
  const [date, setDate] = useState(new Date());
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [newMessage, setNewMessage] = useState(message.message);
  const { user } = useContext(RootContext);

  useEffect(() => {
    setDate(new Date(message.createdAt));
  }, [message]);

  async function handleMessageDelete() {
    let resp = await deleteForumMessage(user._id, forumId, message._id, user.password);
    if (resp.message) {
      alert("Houve um erro ao deletar o comentário, tente mais tarde ou contate a RNBC");
    } else {
      window.location.reload();
    }
    setDeleteDialog(false);
  }

  async function handleMessageUpdate() {
    let resp = await updateForumMessage(user._id, forumId, message._id, user.password, newMessage);
    if (resp.message) {
      alert("Houve um erro ao deletar o comentário, tente mais tarde ou contate a RNBC");
    } else {
      window.location.reload();
    }
    setDeleteDialog(false);
  }

  return (
    <>
      {isOwner && (
        <>
          <Dialog open={editDialog} fullWidth={"md"} onClose={(e) => setEditDialog(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Editar comentário do Fórum</DialogTitle>
            <DialogContent>
              <TextField
                style={{
                  marginTop: 10,
                }}
                fullWidth
                rows="6"
                multiline
                id="message"
                value={newMessage}
                onChange={(e) => {
                  setNewMessage(e.target.value);
                }}
                label="Editar mensagem ao fórum"
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => setEditDialog(false)} color="primary">
                Cancelar
          </Button>
              <Button onClick={handleMessageUpdate} color="primary">
                Enviar
          </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteDialog}
            onClose={(e) => setDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Apagar Comentário?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Você tem certeza que deseja apagar o comentário do fórum?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => setDeleteDialog(false)} color="primary">
                Cancelar
            </Button>
              <Button onClick={handleMessageDelete} color="primary" autoFocus>
                Apagar
            </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Card className={classToApply} style={{ cursor: isOwner ? "auto" : "pointer" }}>
        <CardContent
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 0,
            paddingRight: 5,
          }}
        >
          {message.replyTo && <p><i>Respondendo a <b>@{message.replyTo}</b></i></p>}
          <span>{message.message}</span>
          <Typography
            style={{ fontSize: 12, textAlign: "right", paddingTop: 5 }}
            color="textSecondary"
            gutterBottom
          >
            {message.entityName + " em "}
            <i>{`${date.getDate()}/${date.getMonth() + 1
              }/${date.getFullYear()} às ${date.getHours()}:${date.getMinutes()}h`}</i>
            &nbsp;
          {isOwner &&
              <>
                <EditIcon
                  style={{ marginBottom: "-5px", cursor: "pointer" }}
                  onClick={(e) => setEditDialog(true)}
                />
              &nbsp;
              <DeleteIcon
                  style={{ marginBottom: "-5px", cursor: "pointer" }}
                  onClick={(e) => setDeleteDialog(true)}
                />
              </>
            }
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
