import React from "react";
import { Grid } from "@material-ui/core";
import "../Library/Library.scss";
import Loader from "react-loader-spinner";

export default function Loading({ msg }) {
  return (
    <Grid container>
      <Grid className="loading-grid" item xs={12}>
        <Loader type="Circles" color="#90c16a" height={100} width={100} />
      </Grid>
      <Grid
        style={{
          textAlign: "center",
          fontFamily: "Syne",
          color: "#a73d8f",
          letterSpacing: "0.025em",
        }}
        item
        xs={12}
      >
        <h2>{msg}</h2>
      </Grid>
    </Grid>
  );
}
