import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Typography,
  DialogContent,
  TextField,
  Grid,
  AppBar,
  Toolbar,
  Slide,
  Dialog,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import EventCard from "../Util/EventCard";
import Header from "../Header/Header";
import Loading from "../Util/Loading";
import NotFound from "../Util/NotFound";
import { getUserEvents } from "../../services/user";
import {
  getLibraryEvents,
  createLibraryEvent,
} from "../../services/library";
import { RootContext } from "../AppContext/AppContext";

import "./Activities.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ActivitiesLibrary() {
  const { user } = useContext(RootContext);
  const [activities, setActivities] = useState("seguindo");
  const [agendaFilter, setAgendaFilter] = useState("next");
  const [followingEvents, setFollowingEvents] = useState({
    next: [],
    all: [],
    past: [],
    newEvents: [],
    fetched: false,
  });
  const [libraryEvents, setLibraryEvents] = useState({
    next: [],
    all: [],
    past: [],
    newEvents: [],
    fetched: false,
  });
  const [eventForm, setEventForm] = useState({
    name: "",
    description: "",
    datetime: new Date(),
    link: "",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [creatingEvent, setCreatingEvent] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    error: { status: false, message: "" },
    success: { status: false, message: "" },
  });

  useEffect(() => {
    if (activities === "seguindo" && !followingEvents.fetched) {
      setIsFetching(true);
      fetchFollowingEvents();
    } else if (activities === "agenda" && !libraryEvents.fetched) {
      setIsFetching(true);
      fetchLibraryEvents();
    }
  }, [activities]);

  async function fetchLibraryEvents() {
    let events = await getLibraryEvents(user._id);
    if (events.error) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message:
            "Houve um erro ao acessar informações sobre os eventos. Por favor tente mais tarde",
        },
      });
    } else {
      events["fetched"] = true;
      setLibraryEvents(events);
      setIsFetching(false);
    }
  }


  function fetchFollowingEvents() {
    getUserEvents(user._id)
      .then((response) => response.json())
      .then((data) => {
        data["fetched"] = true;
        setFollowingEvents(data);
        setIsFetching(false);
      })
      .catch((error) => {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message:
              "Houve um erro ao acessar as informações das bibliotecas que você segue",
          },
        });
        setIsFetching(false);
      });
  }

  async function createEvent() {
    if (eventForm.name.length === 0) {
      setAlertStatus({
        ...alertStatus,
        error: {
          status: true,
          message:
            "Evento não foi criado, é obrigatório a indicação de um nome para o evento",
        },
      });
    } else {
      let resp = await createLibraryEvent(user, eventForm);
      if (resp.error) {
        setAlertStatus({
          ...alertStatus,
          error: {
            status: true,
            message:
              "Não foi possível criar o evento, por favor tente mais tarde",
          },
        });
      } else {
        setAlertStatus({
          ...alertStatus,
          success: {
            status: true,
            message: "Evento criado com sucesso!",
          },
        });
        fetchLibraryEvents();
        setActivities("agenda")
      }
    }
    setCreatingEvent(false);
  }

  const handleDateChange = (date) => {
    setEventForm({ ...eventForm, datetime: date });
  };

  return (
    <>
      <Dialog
        fullScreen
        open={creatingEvent}
        onClose={(e) => setCreatingEvent(false)}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative", backgroundColor: "#a73d8f" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => setCreatingEvent(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="create-event-title">
              Novo Evento
            </Typography>
            <Button autoFocus color="inherit" onClick={createEvent}>
              Criar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid style={{ marginTop: 10 }} container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="event-name"
                error={eventForm.name === ""}
                helperText={
                  eventForm.name === ""
                    ? "É obrigatório a indicação de um nome para o evento"
                    : ""
                }
                value={eventForm.name}
                onChange={(e) =>
                  setEventForm({ ...eventForm, name: e.target.value })
                }
                label="Nome do Evento"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ marginTop: 10 }}
                fullWidth
                rows="2"
                multiline
                id="event-description"
                value={eventForm.description}
                onChange={(e) => {
                  setEventForm({ ...eventForm, description: e.target.value });
                }}
                label="Breve descrição do evento"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="event-link"
                style={{ marginTop: 10 }}
                value={eventForm.link}
                onChange={(e) =>
                  setEventForm({ ...eventForm, link: e.target.value })
                }
                label="Link para o evento"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  variant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Data do evento"
                  value={eventForm.datetime}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardTimePicker
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  id="time-picker"
                  ampm={false}
                  label="Hora do evento"
                  value={eventForm.datetime}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} className="activities-btn-grid">
              <Button
                variant="outlined"
                className="cancel-btn"
                onClick={(e) => setCreatingEvent(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="outlined"
                className="create-event-btn"
                onClick={createEvent}
              >
                Criar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Header />
      <div className="activities-page">
        <div className={"btn-grid"} style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            className={
              activities === "seguindo" ? "tab-btn-selected" : "tab-btn"
            }
            onClick={(e) => setActivities("seguindo")}
          >
            Seguindo
          </Button>
          <Button
            variant="outlined"
            className={activities === "agenda" ? "tab-btn-selected" : "tab-btn"}
            onClick={(e) => setActivities("agenda")}
          >
            Agenda
          </Button>
          <Button
            variant="outlined"
            className={activities === "create" ? "tab-btn-selected" : "tab-btn"}
            onClick={(e) => setActivities("create")}
          >
            Criar
          </Button>
        </div>
        {alertStatus.error.status && (
          <Alert
            style={{ marginTop: 5 }}
            severity="error"
            onClose={() => {
              setAlertStatus({ ...alertStatus, error: { status: false } });
            }}
          >
            {alertStatus.error.message}
          </Alert>
        )}
        {alertStatus.success.status && (
          <Alert
            style={{ marginTop: 5 }}
            severity="success"
            onClose={() => {
              setAlertStatus({ ...alertStatus, success: { status: false } });
            }}
          >
            {alertStatus.success.message}
          </Alert>
        )}
        {isFetching ? (
          <Loading
            msg={"Carregando Informações. Por favor aguarde um instantinho"}
          />
        ) : (
            <>
              <Grid container>
                {activities === "seguindo" ? (
                  followingEvents.next.length > 0 ? (
                    followingEvents.next.map((event, key) => (
                      <Grid item xs={12} lg={4} key={key}>
                        <EventCard
                          event={event}
                          isLibrary={false}
                          key={key + "following"}
                        />
                      </Grid>
                    ))
                  ) : (
                      <Grid xs={12}>
                        <NotFound
                          msg={
                            "Não foram encontrados eventos das bibliotecas que você segue"
                          }
                        />
                      </Grid>
                    )
                ) : (
                    ""
                  )}
              </Grid>

              {activities === "agenda" && (
                <div className="agenda">
                  <span className="events-filter-label">
                    Selecione abaixo o filtro para exibição dos eventos:
                </span>

                  <div className="btn-grid">
                    <Button
                      variant="outlined"
                      style={{ minWidth: 104 }}
                      className={
                        agendaFilter === "next" ? "tab-btn-selected" : "tab-btn"
                      }
                      onClick={(e) => setAgendaFilter("next")}
                    >
                      Próximos
                  </Button>
                    <Button
                      variant="outlined"
                      style={{ minWidth: 104 }}
                      className={
                        agendaFilter === "past" ? "tab-btn-selected" : "tab-btn"
                      }
                      onClick={(e) => setAgendaFilter("past")}
                    >
                      Passados
                  </Button>
                  </div>
                  <Grid container>
                    {libraryEvents[agendaFilter].length > 0 ? (
                      libraryEvents[agendaFilter].map((event, key) => (
                        <Grid item xs={12} lg={4} key={key}>
                          <EventCard
                            event={event}
                            isLibrary={true}
                            key={key + "agenda"}
                          />
                        </Grid>
                      ))
                    ) : (
                        <Grid xs={12}>
                          <NotFound msg={"Você ainda não criou nenhum evento"} />
                        </Grid>
                      )}
                  </Grid>

                </div>
              )}
              {activities === "create" && (
                <Grid container>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <p className="events-filter-label">
                      Ao criar eventos as(os) leitoras(es) que seguem sua biblioteca, poderão visualizar no Mapa da Leitura.
                    </p>
                    <p className="events-filter-label">
                      Para adicionar um novo evento clique no botão abaixo:
                    </p>
                  </Grid>

                  <Grid className="btn-grid" style={{ textAlign: "center", padding: "0 20px" }} item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      className="create-event-btn"
                      onClick={(e) => setCreatingEvent(true)}
                    >
                      Criar novo evento
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
      </div>
    </>
  );
}
