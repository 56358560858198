import { getLatLng } from "./location";

//TODO unify this with getLibraryById. This function is being used in Map componenent
export function getLibrary(libraryId) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/library";
  fetchUrl += libraryId ? "/" + libraryId : "";
  return fetch(fetchUrl);
}

export function getLibraryById(libraryId) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/library";
  fetchUrl += libraryId ? "/" + libraryId : "";

  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error in get Library");
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });
    return resp;
  } catch (error) {
    return { error: true };
  }
}

export function getLibraryInfo(libraryId) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/library/${libraryId}/activities-info`;

  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error in get Library info");
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });
    return resp;
  } catch (error) {
    return { error: true };
  }
}

export function deleteEvent(libraryId, eventId, token) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/library/${libraryId}/event/${eventId}`;

  try {
    let res = fetch(fetchUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        return resp;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } catch (error) {
    return error;
  }
}

export function sendEmailToLibrary(libraryId, data) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/send-email/" + libraryId;

  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return { error: false };
        } else {
          throw new Error(
            "Ocorreu um erro tente efetuar o cadastro mais tarde"
          );
        }
      })
      .catch(function () {
        return { error: true };
      });

    return res;
  } catch (error) {
    return { error: true };
  }
}

export function createLibrary(library) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/library";
  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(library),
    })
      .then((response) => {
        if (response.ok) {
          return { error: false };
        } else {
          throw new Error(
            "Ocorreu um erro tente efetuar o cadastro mais tarde"
          );
        }
      })
      .catch(function () {
        return { error: true };
      });

    return res;
  } catch (error) {
    return { error: true };
  }
}

export function updateLibraryImage(libraryId, image) {
  let fetchUrl =
    process.env.REACT_APP_BACKEND_URL + "/library/" + libraryId + "/image";

  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ image: image }),
    })
      .then((response) => {
        if (response.ok) {
          return {};
        } else {
          throw new Error(
            "Ocorreu um erro ao criar o evento. Tente mais tarde"
          );
        }
      })
      .catch(function (error) {
        return { error: true };
      });

    return res;
  } catch (error) {
    return { error: true };
  }
}

export async function updateLibrary(
  libraryId,
  libraryData,
  user,
  addressChanged
) {
  if (addressChanged) {
    let location = await getLatLng(
      libraryData.address.zipCode,
      libraryData.address.addressLine,
      libraryData.address.state
    );
    libraryData.location = {
      type: "Point",
      coordinates: [location.lng, location.lat],
    };
  }

  libraryData.token = user.password;
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/library/" + libraryId;

  try {
    let res = fetch(fetchUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(libraryData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error("Erro");
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function (error) {
        return { error: true };
      });

    return res;
  } catch (error) {
    return { error: true };
  }
}

export function createLibraryEvent(library, event) {
  let fetchUrl =
    process.env.REACT_APP_BACKEND_URL + "/library/" + library._id + "/event";
  let data = { ...event };
  data.libraryId = library._id;
  data.libraryName = library.name;

  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return {};
        } else {
          throw new Error(
            "Ocorreu um erro ao criar o evento. Tente mais tarde"
          );
        }
      })
      .catch(function () {
        return { error: true };
      });

    return res;
  } catch (error) {
    return { error: true };
  }
}

//TODO improve this
export async function searchLibrary(searchTerm, searchFilters, coordinates) {
  let libraryType = ["libraryRnbc"];
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/library/";
  let options;
  if (searchFilters.all) {
    libraryType.push("library");
  }

  if (searchFilters.name) {
    fetchUrl += "name";
    options = { name: searchTerm, libraryType: libraryType };
  } else if (searchFilters.distance) {
    fetchUrl += "near";
    options = {
      distance: searchTerm,
      libraryType: libraryType,
      coordinates: [coordinates.longitude, coordinates.latitude],
    };
  }
  try {
    let res = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error({ error: true });
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });

    return res;
  } catch (error) {
    return { error: true };
  }
}

export async function getLibraryEvents(libraryId) {
  let fetchUrl =
    process.env.REACT_APP_BACKEND_URL + "/library/" + libraryId + "/event";
  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error in get events");
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });
    return resp;
  } catch (error) {
    return { error: true };
  }
}
