import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { getAddress } from "../../services/location";
import { Link } from "react-router-dom";
import RegisterHeader from "./RegisterHeader";
import NumberFormat from "react-number-format";
import PurpleCheckbox from "../Util/PurpleCheckbox";
import { getEmailExists } from "../../services/user";


const institutionOptions = {
  rnbc: "RNBC",
  literasampa: "Litera Sampa",
  beabah: "Beabah",
  soudeminas: "Sou de minas, Uai",
  marleitores: "Mar de Leitores",
  baixada: "Baixada Literária",
  tecendo: "Tecendo uma rede de leitura",
  rbcs: "RBCS",
  jangada: "Jangada Literária",
  releitura: "Releitura PE",
  ilha: "Ilha Literária",
  amazonia: "Amazônia Literária",
  bahia: "Oxe Bahia",
};

const initialInstitutionState = {
  rnbc: false,
  literasampa: false,
  beabah: false,
  soudeminas: false,
  marleitores: false,
  baixada: false,
  tecendo: false,
  rbcs: false,
  jangada: false,
  releitura: false,
  ilha: false,
  amazonia: false,
  bahia: false,
  outro: false,
  outroText: "",
};

function LibraryRegisterGeneralForm({
  libraryForm,
  handleFormUpdate,
  handleNextScreen,
}) {
  const statesOptions = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RO",
    "RS",
    "RR",
    "SC",
    "SE",
    "SP",
    "TO",
  ];
  const [formPart, setFormPart] = useState(0);
  const [formData, setFormData] = useState({
    address: {
      city: "",
      state: "",
      addressLine: "",
      zipCode: "",
      complement: "",
    },
  });

  const [fieldsErrors, setFieldsErrors] = useState({
    password: false,
    email: false,
    name: false,
    phones: false,
    libCreationDate: false,
    zipCode: false,
    city: false,
    addressLine: false,
  });

  const [isPartOfInstitution, setIsPartOfInstitution] = useState("n");

  const [institutionFormData, setInstitutionFormData] = useState(initialInstitutionState);

  useEffect(() => {
    if (libraryForm.password.length > 0) {
      if (libraryForm.password != libraryForm.confirmPassword) {
        setFieldsErrors({ ...fieldsErrors, password: true });
      } else {
        setFieldsErrors({ ...fieldsErrors, password: false });
      }
    }
  }, [libraryForm.password, libraryForm.confirmPassword]);

  useEffect(() => {
    if (isPartOfInstitution === "n") {
      setInstitutionFormData(initialInstitutionState);
    }
  }, [isPartOfInstitution]);

  useEffect(() => {
    if (libraryForm.email.length > 0) {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(libraryForm.email)
      ) {
        setFieldsErrors({ ...fieldsErrors, email: false });
      } else {
        setFieldsErrors({ ...fieldsErrors, email: true });
      }
    }
  }, [libraryForm.email]);

  const handleStateSelectChange = (e) => {
    let newAddress = { ...formData.address };
    newAddress.state = e.target.value;
    setFormData({ ...formData, address: newAddress });
  };

  const handleFormPart = () => {
    let newInstitutions = [];
    for (let key in institutionFormData) {
      if (institutionFormData[key] === true && key != "outro") {
        newInstitutions.push(institutionOptions[key]);
      } else if (institutionFormData[key].length > 0) {
        newInstitutions.push(institutionFormData[key]);
      }
    }
    handleFormUpdate("institution", newInstitutions);

    let newErrorsObj = { ...fieldsErrors };
    let isErrorFound = false;
    if (libraryForm.name.length > 3) {
      newErrorsObj.name = false;
    } else {
      newErrorsObj.name = true;
      isErrorFound = true;
    }

    if (libraryForm.libCreationDate.length > 6) {
      newErrorsObj.libCreationDate = false;
    } else {
      newErrorsObj.libCreationDate = true;
      isErrorFound = true;
    }

    setFieldsErrors(newErrorsObj);

    if (!isErrorFound) {
      setFormPart(1);
    }
  };

  async function checkIfEmailExists(email) {
    let usr = await getEmailExists(email);
    if (usr.error) {
      window.alert("Houve um erro ao verificar o email. Entre em contato com a RNBC ou tente novamente mais tarde");
    } else if(usr.emailExists) {
      setFieldsErrors({ ...fieldsErrors, email: true });
      window.alert("O email indicado já se encontra em uso. Faça login para acessar o mapa");
    } else {
      setFieldsErrors({ ...fieldsErrors, email: false });
    }
  }

  function handleFormValidation() {
    let isErrorFound = false;
    let newErrorsObj = { ...fieldsErrors };
    if (formData.address.city.length > 0) {
      newErrorsObj.city = false;
    } else {
      newErrorsObj.city = true;
      isErrorFound = true;
    }

    if (formData.address.addressLine.length > 0) {
      newErrorsObj.addressLine = false;
    } else {
      newErrorsObj.addressLine = true;
      isErrorFound = true;
    }

    if (libraryForm.phones.length > 8) {
      newErrorsObj.phones = false;
    } else {
      newErrorsObj.phones = true;
      isErrorFound = true;
    }

    if (!isErrorFound) {
      if (newErrorsObj.password || newErrorsObj.email || newErrorsObj.zipCode) {
        isErrorFound = true;
      } else {
        if (
          libraryForm.password.length < 3 &&
          libraryForm.confirmPassword.length < 3
        ) {
          newErrorsObj.password = true;
          isErrorFound = true;
        }

        if (formData.address.zipCode.length === 0) {
          newErrorsObj.zipCode = true;
          isErrorFound = true;
        }

        if (libraryForm.email.length === 0) {
          newErrorsObj.email = true;
          isErrorFound = true;
        }
      }
    }

    setFieldsErrors(newErrorsObj);
    if (!isErrorFound) {
      handleFormUpdate("address", formData.address);
      handleNextScreen();
    }
  };

  function handleAddressItemChange(key, value) {
    let newAddress = { ...formData.address };
    newAddress[key] = value;
    setFormData({ ...formData, address: newAddress });
  }

  function handleCheckboxChange(event) {
    let institutions = { ...institutionFormData };
    institutions[event.target.name] = event.target.checked;
    setInstitutionFormData(institutions);
  };

  function handleZipCodeChange(zipCode) {
    if (zipCode.length === 8) {
      getAddress(zipCode)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          let newAddress = { ...formData.address };
          newAddress.addressLine = `Bairro ${data.bairro} ${data.logradouro}`;
          newAddress.state = data.uf;
          newAddress.city = data.localidade;
          newAddress.zipCode = zipCode;
          setFormData({ ...formData, address: newAddress });
        })
        .catch((error) => console.log(error));
    } else {
      handleAddressItemChange("zipCode", zipCode);
    }
  }

  return (
    <>
      <RegisterHeader
        title={
          formPart === 0 ? "Nome e Instituição" : "Informações de cadastro"
        }
      />
      <Grid className="general-form-page" container spacing={2}>
        {formPart === 0 && (
          <>
            <Grid item xs={12}>
              <span className="form-helper-span">
                A biblioteca <b>faz</b> parte da RNBC (Rede Nacional de Bibliotecas Comunitárias)?
              </span>
              <RadioGroup
                aria-label="biblioteca-rnbc"
                value={libraryForm.role}
                onChange={(e) => handleFormUpdate("role", e.target.value)}
              >
                <FormControlLabel
                  control={<Radio />}
                  value="library"
                  label={"NÃO fazemos parte da RNBC"}
                />
                <FormControlLabel
                  control={<Radio />}
                  value="libraryRnbc"
                  label={"Somos parte da RNBC"}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="create-forum-field"
                fullWidth
                id="library-name"
                error={fieldsErrors.name}
                helperText={
                  fieldsErrors.name
                    ? "Deverá ser indicado um nome de pelo menos 3 letras para a biblioteca"
                    : ""
                }
                value={libraryForm.name}
                onChange={(e) => handleFormUpdate("name", e.target.value)}
                label="Nome da Biblioteca"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <span className="form-helper-span">
                A biblioteca faz parte de alguma rede ou programa de Leitura?
              </span>
              <RadioGroup
                aria-label="biblioteca-rnbc"
                value={isPartOfInstitution}
                onChange={(e) => setIsPartOfInstitution(e.target.value)}
              >
                <FormControlLabel
                  control={<Radio />}
                  value={"n"}
                  label={"Não"}
                />
                <FormControlLabel
                  control={<Radio />}
                  value={"y"}
                  label={"Sim"}
                />
              </RadioGroup>
              {isPartOfInstitution === "y" &&
                <div>
                  <span className="form-helper-span">
                    Selecione as redes/programas (pode selecionar mais de uma) e utilizar o box "Outra" para incluir uma instituição
                    de fora da lista
                  </span>
                  {Object.keys(institutionOptions).map((key) => {
                    return (
                      <div key={key}>
                        <br />
                        <FormControlLabel
                          control={
                            <PurpleCheckbox
                              checked={institutionFormData[key]}
                              onChange={handleCheckboxChange}
                              name={key}
                            />
                          }
                          label={institutionOptions[key]}
                        />
                      </div>
                    );
                  })
                  }
                  <br />
                  <FormControlLabel
                    control={
                      <PurpleCheckbox
                        checked={institutionFormData.outro}
                        onChange={handleCheckboxChange}
                        name="outro"
                      />
                    }
                    label="Outra: "
                  />
                  <TextField
                    className="create-forum-field"
                    id="cursos-outro"
                    value={institutionFormData.outroText}
                    onChange={(e) =>
                      setInstitutionFormData({
                        ...institutionFormData,
                        outroText: e.target.value,
                      })
                    }
                  />
                </div>
              }
            </Grid>
            <Grid item xs={12} lg={6}>
              <span className="form-helper-span">
                Data de criação - Se não souber o dia e mês pode colocar como
                01/01/ano
              </span>
              <TextField
                style={{ marginTop: 5 }}
                className="create-forum-field"
                fullWidth
                id="creation-date"
                error={fieldsErrors.libCreationDate}
                helperText={
                  fieldsErrors.libCreationDate
                    ? "Deverá ser indicada a Data de criação aproximada da biblioteca"
                    : ""
                }
                value={libraryForm.libCreationDate}
                onChange={(e) =>
                  handleFormUpdate("libCreationDate", e.target.value)
                }
                label="Data de criação"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                control={
                  <PurpleCheckbox
                    checked={libraryForm.rnbcNews}
                    onChange={(e) =>
                      handleFormUpdate("rnbcNews", !libraryForm.rnbcNews)
                    }
                    name="rnbc-news"
                  />
                }
                label="Você deseja receber o boletim mensal de informações da RNBC?"
              />
            </Grid>
            <Grid item className="buttons-grid" xs={12}>
              <Button
                className="back-cancel-button"
                variant="outlined"
                component={Link}
                to={"/"}
              >
                Cancelar
              </Button>
              <Button
                className="submit-button"
                variant="outlined"
                onClick={handleFormPart}
              >
                Avançar
              </Button>
            </Grid>
          </>
        )}
        {formPart === 1 && (
          <>
            <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
              <span className="form-helper-span">
                <b>Dados de acesso ao aplicativo</b>
              </span>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="create-forum-field"
                fullWidth
                id="email"
                value={libraryForm.email}
                onBlur={(e) => checkIfEmailExists(e.target.value)}
                onChange={(e) => handleFormUpdate("email", e.target.value)}
                label="Email"
                variant="outlined"
                error={fieldsErrors.email}
                helperText={
                  fieldsErrors.email ? "O email inserido não é valido" : ""
                }
                required
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                className="create-forum-field"
                fullWidth
                id="password"
                type="password"
                value={libraryForm.password}
                onChange={(e) => handleFormUpdate("password", e.target.value)}
                label="Senha"
                variant="outlined"
                required
                error={fieldsErrors.password}
                helperText={fieldsErrors.password ? "A senha não confere" : ""}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                className="create-forum-field"
                fullWidth
                id="confirm-password"
                type="password"
                value={libraryForm.confirmPassword}
                onChange={(e) =>
                  handleFormUpdate("confirmPassword", e.target.value)
                }
                label="Confirmar Senha"
                variant="outlined"
                required
                error={fieldsErrors.password}
                helperText={fieldsErrors.password ? "A senha não confere" : ""}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
              <span className="form-helper-span">
                <b>Dados de Endereço e contato</b>
              </span>
            </Grid>
            <Grid item xs={8} lg={4}>
              <NumberFormat
                id="zipCode"
                variant="outlined"
                required
                fullWidth
                label="CEP"
                format="#####-###"
                value={formData.address.zipCode}
                onValueChange={(values) => {
                  handleZipCodeChange(values.value);
                }}
                error={
                  formData.address.zipCode.length != 8 &&
                    formData.address.zipCode.length > 0
                    ? true
                    : false
                }
                helperText={
                  formData.address.zipCode.length != 8
                    ? "O CEP deve conter 8 digitos"
                    : ""
                }
                customInput={TextField}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <FormControl variant="outlined">
                <InputLabel id="state-label">Estado</InputLabel>
                <Select
                  labelId="state-label"
                  style={{ minWidth: 100 }}
                  id="state"
                  value={formData.address.state}
                  onChange={handleStateSelectChange}
                  label="Estado"
                  required
                >
                  {statesOptions.map((stateOp) => {
                    return (
                      <MenuItem key={stateOp} value={stateOp}>
                        {stateOp}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                className="create-forum-field"
                fullWidth
                id="city"
                value={formData.address.city}
                error={fieldsErrors.city}
                onChange={(e) => {
                  handleAddressItemChange("city", e.target.value);
                }}
                label="Cidade"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <TextField
                className="create-forum-field"
                fullWidth
                id="addressLine"
                error={fieldsErrors.addressLine}
                value={formData.address.addressLine}
                onChange={(e) => {
                  handleAddressItemChange("addressLine", e.target.value);
                }}
                label="Endereço"
                helperText={
                  "Apenas nome da rua, bairro e número, utilize o campo Complemento para indicar outras informações que ajudem a localizar a biblioteca"
                }
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                className="create-forum-field"
                fullWidth
                id="address-complement"
                value={libraryForm.address.complement}
                onChange={(e) => {
                  handleAddressItemChange("complement", e.target.value);
                }}
                label="Complemento"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="create-forum-field"
                fullWidth
                id="phones"
                helperText={
                  fieldsErrors.phones
                    ? "É necessário indicar pelo menos um telefone de contato"
                    : ""
                }
                error={fieldsErrors.phones}
                value={libraryForm.phones}
                onChange={(e) => {
                  handleFormUpdate("phones", e.target.value);
                }}
                label="Telefones"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <span className="form-helper-span">
                Indicar dias da semana e horário de funcionamento da biblioteca
              </span>
              <TextField
                style={{ marginTop: 5 }}
                className="create-forum-field"
                fullWidth
                rows="4"
                multiline
                id="working-time"
                value={libraryForm.workingTime}
                onChange={(e) => {
                  handleFormUpdate("workingTime", e.target.value);
                }}
                label="Horário de Funcionamento"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <span className="form-helper-span">
                Equipe completa da Biblioteca (informe quem são voluntários e
                remunerados) - Essa informação não será pública
              </span>

              <TextField
                style={{ marginTop: 5 }}
                className="create-forum-field"
                fullWidth
                rows="2"
                multiline
                id="team"
                value={libraryForm.team}
                onChange={(e) => {
                  handleFormUpdate("team", e.target.team);
                }}
                label="Equipe da Biblioteca"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", marginTop: 5 }}>
              <span className="form-helper-span">
                <b>Redes sociais</b>
              </span>
            </Grid>
            <Grid item lg={6} xs={12}>
              <span className="form-helper-span">
                Link do perfil da biblioteca no Facebook
              </span>
              <TextField
                style={{ marginTop: 5 }}
                className="create-forum-field"
                fullWidth
                id="facebook"
                value={libraryForm.facebook}
                onChange={(e) => {
                  handleFormUpdate("facebook", e.target.value);
                }}
                label="Facebook"
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <span className="form-helper-span">
                @ do perfil da biblioteca no Instagram
              </span>
              <TextField
                style={{ marginTop: 5 }}
                className="create-forum-field"
                fullWidth
                id="Instagram"
                value={libraryForm.instagram}
                onChange={(e) => {
                  handleFormUpdate("instagram", e.target.value);
                }}
                label="instagram"
                variant="outlined"
              />
            </Grid>
            <Grid className="buttons-grid" item xs={12}>
              <Button
                variant="outlined"
                className="back-cancel-button"
                onClick={(e) => setFormPart(formPart - 1)}
              >
                Voltar
              </Button>
              <Button
                className="submit-button"
                variant="outlined"
                onClick={handleFormValidation}
              >
                Avançar
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default LibraryRegisterGeneralForm;
