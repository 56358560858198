import React, { useState, useEffect, useContext } from "react";
import {
    Button,
    Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Header from "../Header/Header";
import Loading from "../Util/Loading";
import { getLibraryInfo } from "../../services/library";
import FollowingCard from "../Activities/FollowingCard";
import ForumCard from "../Forum/ForumCard";
import { RootContext } from "../AppContext/AppContext";
import { useHistory } from "react-router-dom";

import "../Activities/Activities.scss";

export default function ProfileLibrary() {
    const { user, doLogout } = useContext(RootContext);
    let history = useHistory();
    const [libraryInfo, setLibrayrInfo] = useState({
        fetched: false,
        following: [],
        followers: [],
        foruns: [],
    });
    const [profileMenuSelected, setProfileMenuSelected] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [alertStatus, setAlertStatus] = useState({
        error: { status: false, message: "" },
        success: { status: false, message: "" },
    });

    useEffect(() => {
        setIsFetching(true);
        fetchLibraryInfo();
    }, []);

    async function fetchLibraryInfo() {
        let info = await getLibraryInfo(user._id);
        if (info.error) {
            setAlertStatus({
                ...alertStatus,
                error: {
                    status: true,
                    message:
                        "Houve um erro ao acessar as suas informações. Por favor tente mais tarde",
                },
            });
        } else {
            info["fecthed"] = true;
            setLibrayrInfo(info);
            setIsFetching(false);
        }
    }

    function logout() {
        doLogout();
        history.push("/");
    }

    return (
        <>
            <Header />
            <div className="profile-page">
                <div className="activities-page">
                    {alertStatus.error.status && (
                        <Alert
                            style={{ marginTop: 5 }}
                            severity="error"
                            onClose={() => {
                                setAlertStatus({ ...alertStatus, error: { status: false } });
                            }}
                        >
                            {alertStatus.error.message}
                        </Alert>
                    )}
                    {alertStatus.success.status && (
                        <Alert
                            style={{ marginTop: 5 }}
                            severity="success"
                            onClose={() => {
                                setAlertStatus({ ...alertStatus, success: { status: false } });
                            }}
                        >
                            {alertStatus.success.message}
                        </Alert>
                    )}
                    {isFetching ? (
                        <Loading
                            msg={"Carregando Informações. Por favor aguarde um instantinho"}
                        />
                    ) : (
                            <Grid container>
                                <Grid
                                    style={{ marginTop: "10px" }}
                                    className="mural-menu"
                                    item
                                    xs={12}
                                >
                                    <h3
                                        onClick={(e) =>
                                            profileMenuSelected === 1
                                                ? setProfileMenuSelected(0)
                                                : setProfileMenuSelected(1)
                                        }
                                        className={
                                            profileMenuSelected === 1 ? "mural-menu-selected" : ""
                                        }
                                    >
                                        Seguindo
                                    </h3>
                                    <Grid style={{ display: profileMenuSelected === 1 ? "inline-flex" : "none" }} container>
                                        {libraryInfo.following.map((entity, key) => (
                                            <Grid item xs={12} lg={4} key={key}>
                                                <FollowingCard key={key} entity={entity} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid
                                    style={{ marginTop: "10px" }}
                                    className="mural-menu"
                                    item
                                    xs={12}
                                >
                                    <h3
                                        onClick={(e) =>
                                            profileMenuSelected === 2
                                                ? setProfileMenuSelected(0)
                                                : setProfileMenuSelected(2)
                                        }
                                        className={
                                            profileMenuSelected === 2 ? "mural-menu-selected" : ""
                                        }
                                    >
                                        Seguidores
                                    </h3>
                                    <Grid style={{ display: profileMenuSelected === 2 ? "inline-flex" : "none" }} container>
                                        {libraryInfo.followers.map((entity, key) => (
                                            <Grid item xs={12} lg={4} key={key}>
                                                <FollowingCard key={key} entity={entity} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid
                                    style={{ marginTop: "10px" }}
                                    className="mural-menu"
                                    item
                                    xs={12}
                                >
                                    <h3
                                        onClick={(e) =>
                                            profileMenuSelected === 3
                                                ? setProfileMenuSelected(0)
                                                : setProfileMenuSelected(3)
                                        }
                                        className={
                                            profileMenuSelected === 3 ? "mural-menu-selected" : ""
                                        }
                                    >
                                        Fóruns
                                    </h3>
                                    <Grid style={{ display: profileMenuSelected === 3 ? "inline-flex" : "none" }} container>
                                        {libraryInfo.foruns.map((forum, key) => (
                                            <Grid item xs={12} lg={4} key={key}>
                                                <ForumCard key={key} forum={forum} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid
                                    style={{ marginTop: "10px" }}
                                    className="mural-menu"
                                    item
                                    xs={12}
                                >
                                    <h3
                                        onClick={(e) =>
                                            profileMenuSelected === 5
                                                ? setProfileMenuSelected(0)
                                                : setProfileMenuSelected(5)
                                        }
                                        className={
                                            profileMenuSelected === 5 ? "mural-menu-selected" : ""
                                        }
                                    >
                                        Editar perfil
                                    </h3>
                                    <Grid
                                        style={{
                                            display: profileMenuSelected === 5 ? "block" : "none",
                                        }}
                                        content
                                    >
                                        <Grid style={{ textAlign: "center" }} item xs={12}>
                                            <Button
                                                style={{ minWidth: 300, marginTop: 20 }}
                                                variant="outlined"
                                                className="edit-info-btn"
                                                onClick={(e) => history.push(`library/${user._id}`)}
                                            >
                                                Editar
                                         </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    style={{ marginTop: "10px" }}
                                    className="mural-menu"
                                    item
                                    xs={12}
                                >
                                    <h3
                                        onClick={(e) =>
                                            profileMenuSelected === 7
                                                ? setProfileMenuSelected(0)
                                                : setProfileMenuSelected(7)
                                        }
                                        className={
                                            profileMenuSelected === 7 ? "mural-menu-selected" : ""
                                        }
                                        style={{ color: "red" }}
                                    >
                                        Excluir perfil
                                    </h3>
                                    <Grid
                                        style={{
                                            display: profileMenuSelected === 7 ? "block" : "none",
                                        }}
                                        content
                                    >
                                        <Grid style={{ textAlign: "center" }} item xs={12}>
                                            <Button
                                                style={{ minWidth: 300 }}
                                                className="delete-btn"
                                                variant="outlined"
                                            >
                                                Excluir
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    style={{
                                        textAlign: "center",
                                        marginTop: 50,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                    item
                                    xs={12}
                                >
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        className="edit-info-btn"
                                        style={{ maxWidth: 400 }}
                                        onClick={logout}
                                    >
                                        Sair da minha conta
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                </div>
            </div>
        </>
    );
}
