import React from "react";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const PurpleCheckbox = withStyles({
  root: {
    color: "#a73d8f",
    "&$checked": {
      color: "#a73d8f",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default PurpleCheckbox;
