import React from "react";
import { Grid } from "@material-ui/core";

const notFoundImg = require("../../assets/images/no-results.jpg");

export default function NotFound({ msg }) {
  return (
    <Grid
      style={{
        textAlign: "center",
        paddingTop: 30,
        paddingLeft: 15,
        paddingRight: 15,
      }}
      container
    >
      <Grid item xs={12}>
        <img src={notFoundImg} style={{ width: 200 }} alt="image" />
      </Grid>
      <Grid
        style={{
          textAlign: "center",
          fontFamily: "Syne",
          color: "#a73d8f",
          letterSpacing: "0.025em",
        }}
        item
        xs={12}
      >
        <h2>{msg}</h2>
      </Grid>
    </Grid>
  );
}
