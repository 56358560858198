export function getForum(forumId, skip) {
  let fetchUrl = process.env.REACT_APP_BACKEND_URL + "/forum";
  fetchUrl += forumId ? "/" + forumId : "?skip=" + skip;
  return handleFetch(fetchUrl);
}

export function getFollowingForuns(entityId, skip) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/forum/${entityId}/following?skip=${skip}`;
  return handleFetch(fetchUrl);
}

export function getInteractionsForuns(entityId, skip) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/forum/${entityId}/interaction?skip=${skip}`;
  return handleFetch(fetchUrl);
}

export function addForumMessage(forumId, message) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/forum/${forumId}/${message.entityId}/message`;
  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            "Ocorreu um erro tente efetuar o cadastro mais tarde"
          );
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });

    return res;
  } catch (error) {
    return { error: true };
  }
}

export function createForum(forum, entityId) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/forum/${entityId}`;
  try {
    let res = fetch(fetchUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(forum),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            "Ocorreu um erro tente efetuar o cadastro mais tarde"
          );
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });

    return res;
  } catch (error) {
    return { error: true };
  }
}

export function deleteForumMessage(userId, forumId, messageId, token) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/forum/${forumId}/${userId}/message/${messageId}`;

  try {
    let res = fetch(fetchUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        return resp;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } catch (error) {
    return error;
  }
}

export function updateForumMessage(userId, forumId, messageId, token, message) {
  let fetchUrl = `${process.env.REACT_APP_BACKEND_URL}/forum/${forumId}/${userId}/message/${messageId}`;

  try {
    let res = fetch(fetchUrl, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token, message: message }),
    })
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        return resp;
      })
      .catch(function (error) {
        return error;
      });

    return res;
  } catch (error) {
    return error;
  }
}

function handleFetch(fetchUrl) {
  try {
    let resp = fetch(fetchUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error in get Forum");
        }
      })
      .then((resp) => {
        return resp;
      })
      .catch(function () {
        return { error: true };
      });
    return resp;
  } catch (error) {
    return { error: true };
  }
}
