import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, FormControlLabel } from "@material-ui/core";
import RegisterHeader from "./RegisterHeader";
import PurpleCheckbox from "../Util/PurpleCheckbox";

const accessibilityOptions = {
  deslocamento:
    "O deslocamento é acessível permitindo que cadeiras de rodas circulem sem dificuldades",
  sinalizacao:
    "A biblioteca possui sinalização adequada permitindo que pessoas cegas tenham autonomia na biblioteca",
  braile: "A biblioteca possui materiais em braile",
  audio: "A biblioteca possui materiais audio descritivos",
  libras: "A biblioteca possui intérprete de libras",
};

function LibraryRegisterAccessibility({
  libraryForm,
  handleFormUpdate,
  handleBackScreen,
  handleNextScreen,
  isEditing
}) {
  const [accessibilityFormData, setaccessibilityFormData] = useState({
    deslocamento: false,
    sinalizacao: false,
    braile: false,
    audio: false,
    libras: false,
    outroText: "",
  });

  useEffect(() => {
    if(libraryForm.accessibility){
      let accessibility = [...libraryForm.accessibility];
      let newAccessibilityFormData = {
        deslocamento: false,
        sinalizacao: false,
        braile: false,
        audio: false,
        libras: false,
        outro: false,
        outroText: "",
      };

      Object.keys(accessibilityOptions).forEach((key) => {
        let index = accessibility.indexOf(accessibilityOptions[key]);
        if (index != -1) {
          newAccessibilityFormData[key] = true;
          accessibility.splice(index, 1);
        }
      });

      if (accessibility.length === 1) {
        newAccessibilityFormData.outro = true;
        newAccessibilityFormData.outroText = accessibility[0];
      }

      setaccessibilityFormData(newAccessibilityFormData);
    }
    
  }, []);

  const handleCheckboxChange = (event) => {
    let accessibility = {...accessibilityFormData};
    accessibility[event.target.name] = event.target.checked;
    setaccessibilityFormData(accessibility);

    if(isEditing) updateEditLibraryForm(accessibility);
  };

  const updateEditLibraryForm = (accessibility) => {
    let newAccessibility = [];
    for (let key in accessibility) {
      if (accessibility[key] === true && key != "outro") {
        newAccessibility.push(accessibilityOptions[key]);
      } else if (accessibility[key].length > 0) {
        newAccessibility.push(accessibility[key]);
      }
    }
    handleFormUpdate("accessibility", newAccessibility);
  }; 

  const handleFormValidation = () => {
    let accessibility = [];
    for (let key in accessibilityFormData) {
      if (accessibilityFormData[key] === true && key != "outro") {
        accessibility.push(accessibilityOptions[key]);
      } else if (accessibilityFormData[key].length > 0) {
        accessibility.push(accessibilityFormData[key]);
      }
    }
    handleFormUpdate("accessibility", accessibility);
    handleNextScreen();
  };

  return (
    <>
      {!isEditing && <RegisterHeader title={"Accessibilidade"} />}
      <Grid className="checkbox-page" container spacing={1} style={{ marginTop: 5 }}>
        {Object.keys(accessibilityOptions).map((key) => {
          return (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <PurpleCheckbox
                    checked={accessibilityFormData[key]}
                    onChange={handleCheckboxChange}
                    name={key}
                  />
                }
                label={accessibilityOptions[key]}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <PurpleCheckbox
                checked={accessibilityFormData.outro}
                onChange={handleCheckboxChange}
                name="outro"
              />
            }
            label="Outro: "
          />
          <TextField
            className="create-forum-field"
            id="accessibilidade-outro"
            value={accessibilityFormData.outroText}
            onChange={(e) => {
              setaccessibilityFormData({
                ...accessibilityFormData,
                outroText: e.target.value,
              });
            }}
          />
        </Grid>
        {!isEditing && <Grid className="buttons-grid" item xs={12}>
          <Button
            variant="outlined"
            className="back-cancel-button"
            onClick={handleBackScreen}
          >
            Voltar
          </Button>
          <Button
            className="submit-button"
            variant="outlined"
            onClick={handleFormValidation}
          >
            Avançar
          </Button>
        </Grid>}
      </Grid>
    </>
  );
}

export default LibraryRegisterAccessibility;
